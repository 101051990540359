import { SensorProperties, useAzureMapsContext } from "@smartbuilding/azure-maps-react";
import { evaluateBusynessStatus, evaluateSpaceStatus } from "@smartbuilding/utilities";
import {
    getBuildingCategories,
    getMapLayers,
    getSelectedPathMetadata,
    getSpaceBusynessRooms,
    getSpaceBusynessRoomsWithSensorsMap
} from "../../../redux/Selectors";
import { useEffect, useMemo } from "react";
import { getSensorData } from "@smartbuilding/signalr-redux";
import { useSelector } from "react-redux";

export function useMapData(): void {
    const { mapDataControl, isIndoorMapReady } = useAzureMapsContext();
    const buildingCategories = useSelector(getBuildingCategories);
    const sensorRoomMap = useSelector(getSensorData);
    const spaceBusynessRooms = useSelector(getSpaceBusynessRoomsWithSensorsMap);
    const buildingBusynessRooms = useSelector(getSpaceBusynessRooms);
    const pathMetaData = useSelector(getSelectedPathMetadata);
    const mapLayers = useSelector(getMapLayers);

    const getBusynessSensorData = useMemo(
        () => (): Record<string, SensorProperties> => {
            const idBusynessDataMap: Record<string, SensorProperties> = {};
            for (const id of Object.keys(spaceBusynessRooms)) {
                const busynessStatus = evaluateBusynessStatus(
                    spaceBusynessRooms[id].PeopleCount as number,
                    buildingBusynessRooms.find((room) => room.id === id)?.cardAttributes.roomCapacity ?? 0
                );
                idBusynessDataMap[id] = {
                    busynessStatus: busynessStatus.busynessStatus,
                    busynessStatusColor: busynessStatus.busynessStatusColor
                };
            }
            return idBusynessDataMap;
        },
        [buildingBusynessRooms, spaceBusynessRooms]
    );

    useEffect(() => {
        if (!isIndoorMapReady || !sensorRoomMap || Object.keys(buildingCategories).length === 0) return;

        const idColorDataMap: Record<string, SensorProperties> = {};
        const buildingSensorRooms: string[] = [];
        for (const category of Object.keys(buildingCategories)) {
            if (buildingCategories[category].webSensorLayerEnabled) {
                buildingSensorRooms.push(...buildingCategories[category].spaces);
            }
        }
        for (const id of Object.keys(sensorRoomMap)) {
            if (buildingSensorRooms.includes(id)) {
                idColorDataMap[id] = {
                    sensorStatusColor: evaluateSpaceStatus(
                        sensorRoomMap[id].ConferenceStatus,
                        sensorRoomMap[id].OccupancyStatus
                    ).mapColor
                };
            }
        }
        mapDataControl?.setSensorProperties(idColorDataMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, buildingCategories, sensorRoomMap]);

    useEffect(() => {
        if (!isIndoorMapReady) return;

        const idBusynessDataMap = getBusynessSensorData();
        if (Object.keys(idBusynessDataMap).length > 0) {
            mapDataControl?.setSensorProperties(idBusynessDataMap);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, spaceBusynessRooms]);

    useEffect(() => {
        if (!isIndoorMapReady) return;

        if (mapLayers.route && pathMetaData?.routeData) {
            mapDataControl?.setRouteData(pathMetaData.routeData);
        } else {
            mapDataControl?.setRouteData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, mapLayers.route, pathMetaData]);
}
