export var SensorStateColors;
(function (SensorStateColors) {
    SensorStateColors["GREEN"] = "#a3c494";
    SensorStateColors["ORANGE"] = "#f7ce7c";
    SensorStateColors["RED"] = "#c48282";
    SensorStateColors["TRANSPARENT"] = "transparent";
})(SensorStateColors || (SensorStateColors = {}));
export var LightThemeOptions;
(function (LightThemeOptions) {
    LightThemeOptions[LightThemeOptions["SENSOR_LAYER_OPACITY"] = 1] = "SENSOR_LAYER_OPACITY";
    LightThemeOptions[LightThemeOptions["OUTLINE_LAYER_STROKE_WIDTH"] = 0] = "OUTLINE_LAYER_STROKE_WIDTH";
})(LightThemeOptions || (LightThemeOptions = {}));
export var DarkThemeOptions;
(function (DarkThemeOptions) {
    DarkThemeOptions[DarkThemeOptions["SENSOR_LAYER_OPACITY"] = 0.4] = "SENSOR_LAYER_OPACITY";
    DarkThemeOptions[DarkThemeOptions["OUTLINE_LAYER_STROKE_WIDTH"] = 1] = "OUTLINE_LAYER_STROKE_WIDTH";
})(DarkThemeOptions || (DarkThemeOptions = {}));
export var SensorLayerColors;
(function (SensorLayerColors) {
    SensorLayerColors["DarkThemeBase"] = "#30373f";
    SensorLayerColors["DarkThemeOutline"] = "black";
})(SensorLayerColors || (SensorLayerColors = {}));
export const sensorPolygonColorExp = ["get", "sensorStatusColor"];
export const sensorBaseColorExp = [
    "case",
    ["==", ["get", "sensorStatusColor"], SensorStateColors.TRANSPARENT],
    "transparent",
    SensorLayerColors.DarkThemeBase
];
