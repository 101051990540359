export const indoorMarkerBase = `<svg width="80" height="105" viewBox="0 0 80 105" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dd)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.2422 48.2109C59.3715 45.7058 60 42.9263 60 40C60 28.9543 51.0457 20 40 20C28.9543 20 20 28.9543 20 40C20 42.9261 20.6284 45.7054 21.7576 48.2104H21.7376L21.9674 48.6608C22.1023 48.9411 22.2435 49.2178 22.3908 49.4906L40 83.999L57.5738 49.5558C57.7461 49.2397 57.91 48.9184 58.0654 48.5922L58.26 48.2109H58.2422Z" fill="#0078D4"/>
</g>
<defs>
<filter id="filter0_dd" x="5.6" y="12" width="68.8" height="92.799" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1.2"/>
<feGaussianBlur stdDeviation="1.8"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.108 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="6.4"/>
<feGaussianBlur stdDeviation="7.2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.132 0"/>
<feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
</filter>
</defs>
</svg>`;
