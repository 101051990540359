import { BuildingOverlayLayer, BusynessMapLayer, KioskBlueDotLayer, NullBuildingOverlayLayer, NullKioskLayer, NullPersonLayer, NullUserLocationMarkerLayer, POILayer, PeopleOverlayLayer, PersonLayer, RouteLayer, SensorOverlayLayer, SpacePinsLayer, UserLocationMarkerLayer } from "../Layers";
import { DeviceType } from "../Components/AzureMapComponent.Types";
import { MapEventsEmitter } from "../Services/MapEventsEmitter";
import { MapLayerIds } from "../Constants/Identifiers";
export class MapLayersManager {
    constructor(map, dataSourcesManager, config) {
        this.map = map;
        this.dataSourcesManager = dataSourcesManager;
        this.activeLayers = new Set();
        this.onLayerActive = (layerId) => {
            if (this.activeLayers.add(layerId)) {
                this.updateLayersVisibility();
            }
        };
        this.onLayerInactive = (layerId) => {
            if (this.activeLayers.delete(layerId)) {
                this.updateLayersVisibility();
            }
        };
        this.sensorLayer = new SensorOverlayLayer(this.map, this.dataSourcesManager.getFeaturesDataSource());
        this.busynessLayer = new BusynessMapLayer(this.map, this.dataSourcesManager.getFeaturesDataSource());
        this.spacePinsLayer = new SpacePinsLayer(this.map, this.dataSourcesManager.getFeaturesDataSource());
        this.poiLayer = new POILayer(this.map, this.dataSourcesManager.getPointsOfInterestDataSource(), this.dataSourcesManager.getLevelsDataSource());
        this.peopleOverlayLayer = new PeopleOverlayLayer(this.map, this.dataSourcesManager.getFeaturesDataSource());
        this.routeLayer = new RouteLayer(this.map, this.dataSourcesManager.getRouteDataSource(), this.dataSourcesManager.getRoutePinsDataSource(), this.dataSourcesManager.getRouteClickablePinsDataSource());
        config === DeviceType.Kiosk
            ? (this.kioskBlueDotLayer = new KioskBlueDotLayer(this.map))
            : (this.kioskBlueDotLayer = new NullKioskLayer());
        if (config === DeviceType.Web) {
            this.buildingOverlayLayer = new BuildingOverlayLayer(this.map, this.dataSourcesManager.getBuildingOverlayDataSource());
            this.userLocationMarkerLayer = new UserLocationMarkerLayer(this.map);
            this.personLayer = new PersonLayer(this.map, this.dataSourcesManager.getFeaturesDataSource());
        }
        else {
            this.buildingOverlayLayer = new NullBuildingOverlayLayer();
            this.userLocationMarkerLayer = new NullUserLocationMarkerLayer();
            this.personLayer = new NullPersonLayer();
        }
        this.layersRecord = {
            [MapLayerIds.BusynessLayer]: this.busynessLayer,
            [MapLayerIds.SpacePinsLayer]: this.spacePinsLayer,
            [MapLayerIds.PoiLayer]: this.poiLayer,
            [MapLayerIds.PeopleOverlayLayer]: this.peopleOverlayLayer,
            [MapLayerIds.RouteLayer]: this.routeLayer
        };
        this.subscribeToEvents();
    }
    subscribeToEvents() {
        MapEventsEmitter.getInstance().on("layerActive", this.onLayerActive);
        MapEventsEmitter.getInstance().on("layerInactive", this.onLayerInactive);
    }
    updateLayersVisibility() {
        if (this.activeLayers.has(MapLayerIds.PoiLayer)) {
            Object.keys(this.layersRecord).forEach((key) => {
                if (![MapLayerIds.PoiLayer, MapLayerIds.RouteLayer].includes(key)) {
                    this.layersRecord[key].hide();
                }
            });
        }
        else if (this.activeLayers.has(MapLayerIds.RouteLayer)) {
            this.layersRecord[MapLayerIds.SpacePinsLayer].hide();
        }
        else if (this.activeLayers.has(MapLayerIds.SpacePinsLayer)) {
            this.layersRecord[MapLayerIds.SpacePinsLayer].show();
            this.layersRecord[MapLayerIds.BusynessLayer].hide();
        }
        else {
            Object.keys(this.layersRecord).forEach((key) => {
                this.layersRecord[key].show();
            });
        }
    }
}
