import { layer } from "azure-maps-control";
import { buildingOverlayClusterClickZoomDuration, buildingOverlayClusterLabelLayerIconOptions, buildingOverlayClusterLabelLayerTextOptions, buildingOverlayClusterLayerFilter, buildingOverlayClusterMarkerLayerIconOptions, buildingOverlayClusterMarkerLayerTextOptions, buildingOverlayMarkerLayerFilter, buildingOverlayMarkerLayerIconOptions, buildingOverlayMarkerLayerTextOptions, getBuildingOverlayClusterLabelLayerTextOptions, getBuildingOverlayMarkerLayerTextOptions } from "./BuildingOverlayLayer.Constants";
import { LayerIds } from "../../Constants/Identifiers";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
export class BuildingOverlayLayer {
    constructor(map, dataSource) {
        this.map = map;
        this.dataSource = dataSource;
        this.onClickBuildingMarker = (event) => {
            var _a;
            if (this.onClickBuildingCallback) {
                const shapes = event.map.layers.getRenderedShapes(event.position, LayerIds.BuildingOverlayMarkerLayer);
                if (shapes.length > 0) {
                    const buildingId = (_a = shapes[0].properties) === null || _a === void 0 ? void 0 : _a.buildingId;
                    if (buildingId)
                        this.onClickBuildingCallback(buildingId);
                }
            }
        };
        this.onClickClusterMarker = (event) => {
            const shapes = event.map.layers.getRenderedShapes(event.position, LayerIds.BuildingOverlayClusterMarkerLayer);
            if (shapes.length > 0 && shapes[0].id) {
                this.dataSource.getClusterExpansionZoom(shapes[0].id).then((zoomLvl) => {
                    this.map.setCamera({
                        center: shapes[0].geometry.coordinates,
                        zoom: zoomLvl,
                        type: "ease",
                        duration: buildingOverlayClusterClickZoomDuration
                    });
                });
            }
        };
        this.onThemeChange = (theme) => {
            this.buildingOverlayClusterLabelLayer.setOptions({
                textOptions: getBuildingOverlayClusterLabelLayerTextOptions(theme)
            });
            this.buildingOverlayMarkerLayer.setOptions({
                textOptions: getBuildingOverlayMarkerLayerTextOptions(theme)
            });
        };
        this.buildingOverlayMarkerLayer = new layer.SymbolLayer(this.dataSource, LayerIds.BuildingOverlayMarkerLayer, {
            iconOptions: buildingOverlayMarkerLayerIconOptions,
            textOptions: buildingOverlayMarkerLayerTextOptions,
            filter: buildingOverlayMarkerLayerFilter
        });
        this.buildingOverlayClusterMarkerLayer = new layer.SymbolLayer(this.dataSource, LayerIds.BuildingOverlayClusterMarkerLayer, {
            iconOptions: buildingOverlayClusterMarkerLayerIconOptions,
            textOptions: buildingOverlayClusterMarkerLayerTextOptions,
            filter: buildingOverlayClusterLayerFilter
        });
        this.buildingOverlayClusterLabelLayer = new layer.SymbolLayer(this.dataSource, LayerIds.BuildingOverlayClusterLabelLayer, {
            iconOptions: buildingOverlayClusterLabelLayerIconOptions,
            textOptions: buildingOverlayClusterLabelLayerTextOptions,
            filter: buildingOverlayClusterLayerFilter
        });
        this.map.layers.add([
            this.buildingOverlayClusterMarkerLayer,
            this.buildingOverlayClusterLabelLayer,
            this.buildingOverlayMarkerLayer
        ]);
        this.subscribeToEvents();
    }
    show() {
        this.buildingOverlayMarkerLayer.setOptions({ visible: true });
        this.buildingOverlayClusterMarkerLayer.setOptions({ visible: true });
        this.buildingOverlayClusterLabelLayer.setOptions({ visible: true });
    }
    hide() {
        this.buildingOverlayMarkerLayer.setOptions({ visible: false });
        this.buildingOverlayClusterMarkerLayer.setOptions({ visible: false });
        this.buildingOverlayClusterLabelLayer.setOptions({ visible: false });
    }
    setOnClickBuildingCallback(callback) {
        this.onClickBuildingCallback = callback;
    }
    subscribeToEvents() {
        this.map.events.add("click", this.buildingOverlayMarkerLayer, this.onClickBuildingMarker);
        this.map.events.add("click", this.buildingOverlayClusterMarkerLayer, this.onClickClusterMarker);
        MapEventsEmitter.getInstance().on("themeChanged", this.onThemeChange);
    }
}
