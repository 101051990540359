import "../../Styles/UserLocationMarkerLayerStyles.css";
import { HtmlMarker, data } from "azure-maps-control";
import { LocationMode } from "./UserLocationMarkerLayer.Types";
import { latLngTolerance, resetUserLocationZoomLevel, userLocationIndoorDirectionMarker, userLocationIndoorMarker, userLocationMarkerColor, userLocationPulseMarker, userLocationStaticMarker } from "./UserLocationMarkerLayer.Constants";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
export class UserLocationMarkerLayer {
    constructor(map) {
        this.map = map;
        this.onFloorChange = (floorId) => {
            this.currentFloorId = floorId;
            this.userLocationMarker.setOptions({ visible: this.userLocationFloorId === this.currentFloorId });
            this.panCameraToIndoorMarker();
        };
        this.getHtmlContent = () => {
            if (this.userLocationMode === LocationMode.BuildingLocation) {
                return userLocationStaticMarker;
            }
            else if (this.userLocationMode === LocationMode.GPSLocation) {
                return userLocationPulseMarker;
            }
            else {
                if (this.userLocationBearing === -999999) {
                    return userLocationIndoorMarker;
                }
                else {
                    return userLocationIndoorDirectionMarker;
                }
            }
        };
        this.locationShouldUpdate = (positionData) => {
            if (!this.userLocationPosition ||
                this.userLocationMode !== positionData.locationMode ||
                (positionData.locationMode === LocationMode.IndoorLocation &&
                    this.userLocationFloorId !== positionData.floorId)) {
                return true;
            }
            return (Math.abs(this.userLocationPosition[0] - positionData.lng) > latLngTolerance ||
                Math.abs(this.userLocationPosition[1] - positionData.lat) > latLngTolerance);
        };
        this.userLocationMarker = new HtmlMarker({
            color: userLocationMarkerColor,
            anchor: "center",
            pixelOffset: [0, 0],
            visible: false
        });
        this.map.markers.add(this.userLocationMarker);
        this.subscribeToEvents();
    }
    show() {
        this.userLocationMarker.setOptions({ visible: true });
    }
    hide() {
        this.userLocationMarker.setOptions({ visible: false });
    }
    updateLocation(positionData) {
        if (positionData) {
            if (!this.locationShouldUpdate(positionData))
                return;
            this.userLocationPosition = new data.Position(positionData.lng, positionData.lat);
            this.userLocationMode = positionData.locationMode;
            this.userLocationBearing = positionData.bearing;
            this.userLocationFloorId = positionData.floorId;
            this.userLocationMarker.setOptions({
                position: this.userLocationPosition,
                htmlContent: this.getHtmlContent(),
                visible: positionData.locationMode !== LocationMode.IndoorLocation ||
                    this.userLocationFloorId === this.currentFloorId
            });
            this.panCameraToIndoorMarker();
        }
        else {
            this.userLocationPosition = undefined;
            this.userLocationMode = undefined;
            this.userLocationBearing = undefined;
            this.hide();
        }
    }
    resetToUserLocation() {
        if (this.userLocationPosition) {
            this.map.setCamera({
                center: this.userLocationPosition,
                bearing: this.userLocationBearing,
                zoom: resetUserLocationZoomLevel
            });
        }
    }
    subscribeToEvents() {
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorChange);
    }
    panCameraToIndoorMarker() {
        if (this.userLocationMode === LocationMode.IndoorLocation &&
            this.userLocationPosition &&
            this.userLocationFloorId === this.currentFloorId) {
            this.map.setCamera({
                center: this.userLocationPosition,
                bearing: this.userLocationBearing,
                type: "ease",
                duration: 200
            });
        }
    }
}
