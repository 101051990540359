import { iconElevatorDown } from "./IconElevatorDown";
import { iconElevatorUp } from "./IconElevatorUp";
import { iconEnd } from "./IconEnd";
import { iconStairsDown } from "./IconStairsDown";
import { iconStairsUp } from "./IconStairsUp";
import { iconStart } from "./IconStart";
export var RouteIconLabels;
(function (RouteIconLabels) {
    RouteIconLabels["Start"] = "Start";
    RouteIconLabels["End"] = "End";
    RouteIconLabels["ElevatorUp"] = "ElevatorUp";
    RouteIconLabels["ElevatorDown"] = "ElevatorDown";
    RouteIconLabels["StairUp"] = "StairUp";
    RouteIconLabels["StairDown"] = "StairDown";
})(RouteIconLabels || (RouteIconLabels = {}));
export const routeMarkerIcons = {
    Start: iconStart,
    End: iconEnd,
    ElevatorUp: iconElevatorUp,
    ElevatorDown: iconElevatorDown,
    StairUp: iconStairsUp,
    StairDown: iconStairsDown
};
export const routeMarkerColors = {
    Start: "#0078D4",
    End: "#0F7B0C",
    ElevatorUp: "#0078D4",
    ElevatorDown: "#0078D4",
    StairUp: "#0078D4",
    StairDown: "#0078D4"
};
