import {
    AdtConverters,
    IAzureDigitalTwinV3SpaceRetrieve,
    IAzureDigitalTwinV3UserRetrieve
} from "@smartbuilding/utilities";
import {
    Area,
    AzureMapData,
    BasicDigitalTwin,
    Building,
    BuildingType,
    Calendar,
    Employee,
    Floor,
    Location,
    POI,
    Region,
    Space,
    Value
} from "@dip/adt-types";
import {
    BuildingDataRetrievedAction,
    BuildingSelectedAction,
    DefaultBuildingSelectedAction,
    FloorSelectedAction,
    InstantBookingRoomSelectedAction,
    RoomSelectedAction,
    RoomsRetrievedAction,
    SpaceCategoryActions,
    SpaceCategorySelectedAction,
    SpaceRetrieveActions,
    SpaceSelectedActions,
    buildingsWithMapDataRetrieved,
    categoriesRetrieved,
    categorySelectionComplete,
    clearDetailsPanelStack,
    floorSelected,
    pointsOfInterestRetrieved,
    retrievedRegionsData,
    roomImageRetrieved,
    roomSelected,
    roomsRetrieved,
    setBuilding,
    setCategory,
    setDeepLinkFloor,
    setDeepLinkRoom,
    setFloor,
    setMenuPanelCategory,
    setRoom,
    spaceBusynessRuleSetRetrieved,
    updateBuilding
} from "../Actions";
import { CallEffect, all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
    IBuilding,
    IConfigLocation,
    IDeviceConfigKeyVaultResponse,
    IDeviceConfigStore,
    IRoomInfo,
    ISpaceInfo,
    PoiFloorMapping,
    RootCategoryLabel
} from "../Types";
import { IDipResponse, dipSagas, getTwins } from "@dip/redux-sagas";
import { IPeopleService, IPerson } from "@smartbuilding/people-service";
import {
    IRoomImageProvider,
    ISpace,
    ISpaceCategoriesService,
    ISpaceCategory
} from "@smartbuilding/smartbuilding-api-service";
import { RoomSubTypes, SmartSpace } from "@smartbuilding/adt-v2-types";
import {
    createAppStateUpdateAction,
    startTrackingEventAction,
    stopTrackingEventAction
} from "../Actions/AppMetricsActions";
import {
    getAvailableCapacityValue,
    getConferenceStatusValue,
    getOccupancyStatusValue,
    getPeopleCountValue,
    isHotDeskingEnabled,
    isPeopleDensityEnabled
} from "@smartbuilding/room-card-service";
import {
    getBuilding,
    getBuildingCategories,
    getBuildingMap,
    getBuildings,
    getCategory,
    getDeepLinkFloorId,
    getDeepLinkRoomId,
    getDeviceConfigData,
    getDeviceConfigFloorId,
    getDeviceConfigLocation,
    getFloorId,
    getFloors,
    getRoomMap,
    getRooms
} from "../Selectors";
import { AppState } from "../Types/AppMetricTypes";
import { IConfigurationService } from "@smartbuilding/configuration-provider";
import { ILogger } from "@smartbuilding/log-provider";
import { IPoiDetailsService } from "@smartbuilding/poi-service";
import { IRoomImageService } from "@smartbuilding/mrcdp-client";
import { ISpaceRepo } from "@smartbuilding/adt-v2-api";
import { IWebClientConfiguration } from "../../constants";
import { QueryBuilder } from "@dip/querybuilder";
import { combineCategorySpaceId } from "../../components/DetailsPanel/ListViewCard/useListViewCardUtilities";
import { electronService } from "@smartbuilding/electron-service";
import { spaceCapabilityConverter } from "../SagaHelpers/SpaceCapabilityConverter";
import { subscribeToAllSpaces } from "@smartbuilding/signalr-redux";

export class SpaceSaga {
    private buildingSelectionEvent = "Building Selected Event";
    private buildingInformationRetrieveEvent = "Building Information Retrieve Event";
    private pointOfInterestRetrieveEvent = "Point of Interest Retrieve Event";
    private buildingSpacesRetrieval = "Retrieving Building Spaces Event";
    private buildingWhitelist = new Set<string>(["122", "3", "4", "5", "6", "7", "8", "9"]);
    private mrcdpRoomTypeWhitelist = this.generateRoomTypeWhitelist(false);
    private blobRoomTypeWhitelist = this.generateRoomTypeWhitelist(true);
    private v2MapBuildings: string[] = [
        "6",
        "16",
        "17",
        "18",
        "25",
        "27",
        "28",
        "35",
        "36",
        "37",
        "40",
        "41",
        "42",
        "43",
        "44",
        "50",
        "83",
        "85",
        "87",
        "88"
    ];
    public constructor(
        private spaceRepo: ISpaceRepo, // TODO: Joey todo -> remove this in clean up PR
        private roomImageService: IRoomImageService,
        private roomImageProvider: IRoomImageProvider,
        private poiService: IPoiDetailsService,
        private peopleService: IPeopleService,
        private logger: ILogger,
        private configService: IConfigurationService<IWebClientConfiguration>,
        private spaceCategoryService: ISpaceCategoriesService
    ) {
        this.watcher = this.watcher.bind(this);
        this.handleBuildingSelection = this.handleBuildingSelection.bind(this);
        this.handleBuildingDataRetrieved = this.handleBuildingDataRetrieved.bind(this);
        this.handleRoomRetrieve = this.handleRoomRetrieve.bind(this);
        this.handleSpaceSelection = this.handleSpaceSelection.bind(this);
        this.handleFloorSelection = this.handleFloorSelection.bind(this);
        this.retrieveBuildingInfo = this.retrieveBuildingInfo.bind(this);
        this.retrieveBuildingsWithMapData = this.retrieveBuildingsWithMapData.bind(this);
        this.retrieveBuildingsWithRegionData = this.retrieveBuildingsWithRegionData.bind(this);
        this.retrieveSpaces = this.retrieveSpaces.bind(this);
        this.retrieveSpaceCalendars = this.retrieveSpaceCalendars.bind(this);
        this.retrieveSpaceSensorValues = this.retrieveSpaceSensorValues.bind(this);
        this.retrievePointsOfInterest = this.retrievePointsOfInterest.bind(this);
        this.handleSetToDefaultBuilding = this.handleSetToDefaultBuilding.bind(this);
        this.handleCategorySelection = this.handleCategorySelection.bind(this);
        this.setUserDefaultBuilding = this.setUserDefaultBuilding.bind(this);
    }

    public *watcher(): Generator {
        yield all([
            takeLatest(SpaceSelectedActions.BUILDING_SELECTED, this.handleBuildingSelection),
            takeEvery(SpaceRetrieveActions.BUILDING_DATA_RETRIEVED, this.handleBuildingDataRetrieved),
            takeEvery(SpaceRetrieveActions.ROOMS_RETRIEVED, this.handleRoomRetrieve),
            takeEvery(SpaceSelectedActions.FLOOR_SELECTED, this.handleFloorSelection),
            takeEvery(SpaceSelectedActions.ROOM_SELECTED, this.handleSpaceSelection),
            takeEvery(SpaceSelectedActions.INSTANT_BOOKING_ROOM_SELECTED, this.handleSpaceSelection),
            takeEvery(SpaceSelectedActions.DEFAULT_SELECTED_BUILDING, this.handleSetToDefaultBuilding),
            takeEvery(SpaceCategoryActions.SPACE_CATEGORY_SELECTED, this.handleCategorySelection)
        ]);
    }

    /**
     * Retrieves base building information upon selection
     * This information includes the children (floors), location and map data.
     * It does not retrieve space information in this pass. Rather this is handled after the base building information has been retrieved.
     * In the the handleBuildingDataRetrieved function, which listens to the action BuildingDataRetrieved.
     * @param action @type {BuildingSelectedAction}
     */
    private *handleBuildingSelection(action: BuildingSelectedAction): Generator {
        const buildingId = action.payload;
        yield put(startTrackingEventAction(this.buildingSelectionEvent));
        yield put(setCategory(undefined));
        yield put(setMenuPanelCategory(RootCategoryLabel));
        yield put(clearDetailsPanelStack());
        yield call(this.retrieveBuildingInfo, buildingId);
        yield call(this.retrievePointsOfInterest, buildingId);

        const buildings = (yield select(getBuildings)) as IBuilding[];
        // Determine if we need to retrieve the full set of buildings. Only want to do this once, after loading initial building
        if (buildings.length <= 1) {
            yield put(createAppStateUpdateAction(AppState.Loaded));
            yield call(this.retrieveBuildingsWithMapData);
            yield call(this.retrieveBuildingsWithRegionData);
        }
        yield put(stopTrackingEventAction(this.buildingSelectionEvent));
    }

    /**
     * Saga that runs after base building data has been retrieved.
     * The purpose of this saga is to retrieve space related information for each floor.
     *   1) Spaces on the floor without any additional information
     *   2) Spaces per floor that have sensor values attached
     *   3) Spaces per floor that have calendars
     * @param action @type {BuildingDataRetrievedAction}
     */
    private *handleBuildingDataRetrieved(action: BuildingDataRetrievedAction): Generator {
        const floors = action.payload.floors;
        const buildingId = action.payload.id;

        // Retrieve all space information
        yield put(startTrackingEventAction(this.buildingSpacesRetrieval));
        const rooms = (yield select(getRooms)) as IRoomInfo[];
        yield call(this.retrieveSpaces, buildingId);
        const buildingFloors = (yield select(getFloors)) as ISpaceInfo[];
        const floorIds: string[] = [];
        buildingFloors.forEach((floor) => floorIds.push(floor.id));
        if (floorIds && floorIds.length > 0) {
            yield put(subscribeToAllSpaces(buildingId, floorIds));
        } else {
            this.logger.logError(new Error(`[SignalRSaga] No valid floors for ${buildingId}`));
        }
        if (floors) {
            const sensorRetrieve: CallEffect[] = [];
            const calendarRetrieve: CallEffect[] = [];
            for (const floor of floors) {
                const roomsOnFloor = rooms?.filter((room) => room.cardAttributes.floorId === floor.id);
                if (!roomsOnFloor?.length) {
                    sensorRetrieve.push(call(this.retrieveSpaceSensorValues, buildingId, floor.id));
                    calendarRetrieve.push(call(this.retrieveSpaceCalendars, buildingId, floor.id));
                }
            }

            yield all(sensorRetrieve);
            yield all(calendarRetrieve);
        }
        yield put(stopTrackingEventAction(this.buildingSpacesRetrieval));
    }

    /**
     * Saga that runs after list of rooms have been retrieved
     * The purpose of this saga is to do any post-processing for particular rooms.
     * In this case we want to retrieve room images for a subset of rooms, which is being handled here.
     */
    private *handleRoomRetrieve(action: RoomsRetrievedAction): Generator {
        const rooms = action.payload.data;
        let index = 0;
        for (const room of rooms) {
            const isValidRoomType = this.mrcdpRoomTypeWhitelist.has(room.type);
            const roomStore = ((yield select(getRoomMap)) as Record<string, IRoomInfo>)[room.id];
            const building = (yield select(getBuilding)) as IBuilding;
            const isImageFromMrcdp =
                isValidRoomType && !roomStore?.cardAttributes?.icon && building && building.rooms?.includes(room.id);
            const isImageFromLocalBlob =
                this.buildingWhitelist.has(building.name) && this.blobRoomTypeWhitelist.has(room.type);
            if (isImageFromMrcdp) {
                // Default image is retrieved from the local directory and obtained by round-robin fashion of default assets
                const defaultImage = `Assets/MeetingRooms/${index++ % 5}.jpg`;
                // Inserting into the store the default image for the room so that we have an image while awaiting for response from the server
                // Also serves as a lock to ensure that the same room isn't retrieved more than once
                yield put(roomImageRetrieved({ id: room.id, image: defaultImage }));
                const image = (yield call(
                    this.roomImageService.getRoomImageUrl,
                    room.id,
                    building.name + "/" + roomStore.name
                )) as string;
                // Once we have the image from the server we can insert into the store overriding the existing icon
                if (image) {
                    yield put(roomImageRetrieved({ id: room.id, image: image }));
                }
            } else if (isImageFromLocalBlob) {
                try {
                    const image = (yield call(
                        [this.roomImageProvider, this.roomImageProvider.getLocalRoomImageUrl],
                        building.name + "%2F" + roomStore.name
                    )) as string;
                    if (image) yield put(roomImageRetrieved({ id: room.id, image: image }));
                } catch (error) {
                    this.logger.logError(error as Error);
                }
            }
        }
    }

    /**
     * Saga that runs when a room is selected in the application.
     * Ensures that if we select a room we have all the information needed for the building and have selected the correct floor
     * If there is nothing to do we propagate the action that room selection is complete
     */
    private *handleSpaceSelection(action: RoomSelectedAction | InstantBookingRoomSelectedAction): Generator {
        const isInstantBooking = typeof action.payload !== "string";
        const spaceId = !isInstantBooking
            ? (action as RoomSelectedAction).payload
            : (action as InstantBookingRoomSelectedAction).payload.roomId;
        try {
            const building = (yield select(getBuilding)) as IBuilding;
            const room = building?.rooms?.includes(spaceId);
            if (!room) {
                const query = QueryBuilder.from(Space).where((q) => q.compare(Space, (s) => s.$dtId.equals(spaceId)));
                const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Space[]>;
                this.checkDipError(
                    response,
                    "[DIP Response] No space data available",
                    "Fail to retrieve the selected space"
                );

                if (response.data.length > 0) {
                    const space = AdtConverters.formatIntoSmartSpace(
                        response.data[0] as IAzureDigitalTwinV3SpaceRetrieve
                    );
                    if (space.floorId) {
                        yield put(setFloor(space.floorId));
                    }
                }
            } else {
                yield put(
                    roomSelected(
                        spaceId,
                        isInstantBooking
                            ? (action as InstantBookingRoomSelectedAction).payload.instantBookingConfirmationInfo
                            : undefined
                    )
                );
            }
        } catch (error) {
            this.logger.logError(error as Error, {
                SpaceId: spaceId,
                Message: "Failed to set space"
            });
        }
    }

    /**
     * Saga that runs when a floor is selected in the application.
     * Ensures that if we select a floor we have all the information needed either for the building
     * If there is nothing to do we propagate the action that floor selection is complete
     */
    private *handleFloorSelection(action: FloorSelectedAction): Generator {
        const floorId = action.payload;
        try {
            const building = (yield select(getBuilding)) as IBuilding;
            const floor = building?.floors?.find((floor) => floor.id === floorId);
            if (!floor) {
                const query = QueryBuilder.from(Floor)
                    .where((q) => q.compare(Floor, (f) => f.$dtId.equals(floorId)))
                    .join(Floor, Building, (f) => f.hasParent)
                    .addSelect(Building);
                const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Floor[]>;
                this.checkDipError(
                    response,
                    "[DIP Response] No floor data available",
                    "Failed to retrieve the selected floor"
                );

                if (response.data.length > 0) {
                    const floor = AdtConverters.formatIntoSmartSpace(
                        response.data[0] as IAzureDigitalTwinV3SpaceRetrieve
                    );
                    if (floor.parent) {
                        yield put(setBuilding(floor.parent.dtId));
                        yield put(floorSelected(floorId));
                    }
                }
            } else {
                yield put(floorSelected(floorId));
            }
        } catch (error) {
            this.logger.logError(error as Error, {
                FloorId: floorId,
                Message: "Failed to set floor"
            });
        }
    }

    private *handleCategorySelection(action: SpaceCategorySelectedAction): Generator {
        if (!action.payload) return;

        const categories = (yield select(getBuildingCategories)) as Record<string, ISpaceCategory> | undefined;
        if (!categories) {
            yield put(categorySelectionComplete([]));
        } else {
            yield put(categorySelectionComplete(combineCategorySpaceId(categories, action.payload)));
        }
    }

    private *retrieveBuildingInfo(buildingId: string): Generator {
        const queryBuilder = QueryBuilder.from(Building)
            .join(Building, AzureMapData, (b) => b.hasAzureMapData)
            .join(Building, Floor, (b) => b.hasChildren)
            .join(Building, Location, (b) => b.hasLocation)
            .where((q) => q.compare(Building, (b) => b.$dtId.equals(buildingId)))
            .addSelect(AzureMapData)
            .addSelect(Floor)
            .addSelect(Location);
        try {
            yield put(startTrackingEventAction(this.buildingInformationRetrieveEvent));
            const response = (yield dipSagas.get(getTwins(queryBuilder))) as IDipResponse<Building[]>;
            this.checkDipError(
                response,
                "[DIP Response] No building data available",
                "Failed to retrieve the selected building"
            );

            if (response.data.length > 0) {
                const building = AdtConverters.formatIntoSmartSpace(
                    response.data[0] as IAzureDigitalTwinV3SpaceRetrieve
                );
                const env = (yield call([this.configService, this.configService.getSetting], "Environment")) as string;
                yield put(updateBuilding(building, env));
                yield put(stopTrackingEventAction(this.buildingInformationRetrieveEvent));
            }
        } catch (error) {
            this.logger.logError(error as Error, {
                BuildingId: buildingId,
                Message: "Failed to retrieve base building"
            });
        }
    }

    private *retrieveBuildingsWithMapData(): Generator {
        try {
            let query = QueryBuilder.from(Building)
                .join(Building, AzureMapData, (b) => b.hasAzureMapData)
                .where((q) => q.compare(Building, (b) => b.name.in(this.v2MapBuildings)))
                .addSelect(AzureMapData);
            const env = (yield call([this.configService, this.configService.getSetting], "Environment")) as string;
            if (env === "Production") {
                query = QueryBuilder.from(Building)
                    .join(Building, AzureMapData, (b) => b.hasAzureMapData)
                    .join(Building, BuildingType, (b) => b.isOfBuildingType)
                    .where((q) => q.compare(BuildingType, (bt) => bt.description.notEquals("Parking Garage")))
                    .where((q) => q.compare(Building, (b) => b.status.notEquals("Inactive")))
                    .where((q) => q.compare(Building, (b) => b.name.in(this.v2MapBuildings)))
                    .addSelect(AzureMapData)
                    .addSelect(BuildingType);
            }
            const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Building[]>;
            this.checkDipError(
                response,
                "[DIP Response] No buildings data available",
                "Fail to retrieve the list of buildings"
            );

            if (response.data.length > 0) {
                const buildings = response.data.map((building) =>
                    AdtConverters.formatIntoSmartSpace(building as IAzureDigitalTwinV3SpaceRetrieve)
                );
                yield put(buildingsWithMapDataRetrieved(buildings));
            }
        } catch (error) {
            this.logger.logError(error as Error);
        }
    }

    private *retrieveBuildingsWithRegionData(): Generator {
        try {
            const query = QueryBuilder.from(Region);
            const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Region[]>;
            this.checkDipError(
                response,
                "[DIP Response] No regions data available",
                "Failed to retrieve the list of regions"
            );

            if (response.data.length > 0) {
                const regions = response.data.map((region) =>
                    AdtConverters.formatIntoSmartSpace(region as IAzureDigitalTwinV3SpaceRetrieve)
                );
                yield put(retrievedRegionsData(regions));
            }
        } catch (error) {
            this.logger.logError(error as Error);
        }
    }

    private *retrieveSpaces(buildingId: string): Generator {
        try {
            const { categoryMap, spaces } = (yield call(
                [this.spaceCategoryService, this.spaceCategoryService.getSpacesInBuildingGroupedByCategory],
                buildingId
            )) as { categoryMap: Record<string, ISpaceCategory>; spaces: Record<string, ISpace> };
            let tags: Record<string, string[]> = {};
            try {
                tags = (yield call(
                    [this.spaceCategoryService, this.spaceCategoryService.getSpaceObjectDetectionTags],
                    buildingId
                )) as Record<string, string[]>;
            } catch (error) {
                this.logger.logError(error as Error, {
                    BuildingId: buildingId,
                    Message: "Failed to retrieve space tags"
                });
            }
            let spaceBusynessRuleSet: RoomSubTypes[] = [];
            try {
                spaceBusynessRuleSet = (yield call([
                    this.spaceCategoryService,
                    this.spaceCategoryService.getSpaceBusynessRuleSet
                ])) as Array<RoomSubTypes>;
            } catch (error) {
                this.logger.logError(error as Error, {
                    BuildingId: buildingId,
                    Message: "Failed to retrieve space rule set"
                });
            }

            const rooms: Array<IRoomInfo> = Object.values(spaces).map((space) => ({
                id: space.id,
                name: space.name,
                type: space.categoryName as RoomSubTypes,
                tags: tags[space.id] || [],
                cardAttributes: {
                    id: space.id,
                    spaceName: space.name,
                    floorId: space.floorId,
                    featureId: space.featureId,
                    friendlyName: space.friendlyName,
                    roomCapacity: space.roomCapacity,
                    roomCapabilities: spaceCapabilityConverter(space.capabilities)
                }
            }));

            yield put(roomsRetrieved(buildingId, rooms));
            yield put(categoriesRetrieved(buildingId, categoryMap));
            yield put(spaceBusynessRuleSetRetrieved(spaceBusynessRuleSet));

            const category = (yield select(getCategory)) as string;
            if (category) {
                yield put(categorySelectionComplete(combineCategorySpaceId(categoryMap, category)));
            }

            const deepLinkFloorId = (yield select(getDeepLinkFloorId)) as string;
            if (deepLinkFloorId) {
                yield put(setFloor(deepLinkFloorId));
                yield put(setDeepLinkFloor(undefined));
            }
            const deepLinkRoomId = (yield select(getDeepLinkRoomId)) as string;
            if (deepLinkRoomId) {
                yield put(setRoom(deepLinkRoomId));
                yield put(setDeepLinkRoom(undefined));
            }
        } catch (error) {
            this.logger.logError(error as Error, {
                BuildingId: buildingId,
                Message: "Failed to retrieve spaces"
            });
        }
    }

    private *retrieveSpaceCalendars(buildingId: string, floorId: string): Generator {
        try {
            const query = QueryBuilder.from(Area)
                .where((q) => q.compare(Area, (a) => a.buildingId.equals(buildingId)))
                .where((q) => q.compare(Area, (a) => a.floorId.equals(floorId)))
                .join(Area, Calendar, (a) => a.hasCalendar)
                .addSelect(Calendar);
            const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Area[]>;
            if (response.data.length > 0) {
                const data = response.data.map((area) =>
                    AdtConverters.formatIntoSmartSpace(area as IAzureDigitalTwinV3SpaceRetrieve)
                );
                const rooms = data.map((room) => {
                    const roomInfo = this.formatBaseRoomData(floorId, room);
                    return {
                        ...roomInfo,
                        cardAttributes: {
                            ...roomInfo.cardAttributes,
                            conferenceRoomAlias: room.calendar?.alias,
                            conferenceRoomSize: room.calendar?.capacity
                        }
                    };
                });
                yield put(roomsRetrieved(buildingId, rooms));
            }
            if (response && response.error) {
                this.logger.logError(new Error("[DIP Response] No calendar data"), {
                    error: response.error,
                    status: response.status,
                    message: response.statusText
                });
            }
        } catch (error) {
            this.logger.logError(error as Error, {
                BuildingId: buildingId,
                FloorId: floorId,
                Message: "Failed to retrieve spaces with calendars"
            });
        }
    }

    private *retrieveSpaceSensorValues(buildingId: string, floorId: string): Generator {
        try {
            const query = QueryBuilder.from(Area)
                .where((q) => q.compare(Area, (a) => a.buildingId.equals(buildingId)))
                .where((q) => q.compare(Area, (a) => a.floorId.equals(floorId)))
                .join(Area, Value, (a) => a.hasValues)
                .addSelect(Value);
            const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Area[]>;
            if (response.data.length > 0) {
                const data = response.data.map((area) =>
                    AdtConverters.formatIntoSmartSpace(area as IAzureDigitalTwinV3SpaceRetrieve)
                );
                const rooms: Array<IRoomInfo> = data.map((room) => {
                    const roomInfo = this.formatBaseRoomData(floorId, room);
                    return {
                        ...roomInfo,
                        cardAttributes: {
                            ...roomInfo.cardAttributes,
                            availableCapacity: getAvailableCapacityValue(room),
                            peopeCount: getPeopleCountValue(room),
                            occupancyStatus: getOccupancyStatusValue(room),
                            conferenceStatus: getConferenceStatusValue(room),
                            isPeopleDensityEnabled: isPeopleDensityEnabled(room),
                            totalCapacity: room?.seatCount || 0
                        }
                    };
                });
                yield put(roomsRetrieved(buildingId, rooms));
            }

            if (response && response.error) {
                this.logger.logError(new Error("[DIP Response] No sensor data"), {
                    error: response.error,
                    status: response.status,
                    message: response.statusText
                });
            }
        } catch (error) {
            this.logger.logError(error as Error, {
                BuildingId: buildingId,
                FloorId: floorId,
                Message: "Failed to retrieve spaces with sensor values"
            });
        }
    }

    private *retrievePointsOfInterest(buildingId: string): Generator {
        try {
            yield put(startTrackingEventAction(this.pointOfInterestRetrieveEvent));
            const buildingMap = (yield select(getBuildingMap)) as Record<string, IBuilding>;
            const currentFloorId = (yield select(getFloorId)) as string;
            let pointsOfInterest: PoiFloorMapping | undefined = buildingMap[buildingId]?.pointsOfInterest;
            if (!pointsOfInterest) {
                const query = QueryBuilder.from(POI)
                    .where((q) => q.compare(POI, (p) => p.buildingId.equals(buildingId)))
                    .where((q) => q.compare(POI, (p) => p.status.equals("Active")))
                    .join(POI, Location, (p) => p.hasLocation)
                    .addSelect(Location);
                const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<POI[]>;
                const poiData = response.data.map((d) =>
                    AdtConverters.formatIntoSmartSpace(d as IAzureDigitalTwinV3SpaceRetrieve)
                );
                pointsOfInterest = this.poiService.getAllPoiDetails(poiData);
            }

            if (electronService.isElectron()) {
                const deviceConfigFloorId = (yield select(getDeviceConfigFloorId)) as string | undefined;
                const deviceConfigLocation = (yield select(getDeviceConfigLocation)) as IConfigLocation | undefined;

                if (deviceConfigLocation) {
                    if (!pointsOfInterest[currentFloorId]) {
                        pointsOfInterest[currentFloorId] = {};
                    }
                    pointsOfInterest[currentFloorId].KioskLocation = undefined;
                    if (deviceConfigFloorId && deviceConfigFloorId === currentFloorId) {
                        pointsOfInterest[currentFloorId].KioskLocation = [
                            { longitude: deviceConfigLocation.longitude, latitude: deviceConfigLocation.latitude }
                        ];
                    }
                } else {
                    const deviceConfig = (yield select(getDeviceConfigData)) as IDeviceConfigStore | undefined;
                    if (deviceConfig?.hardwareId) {
                        const kioskLocationsFromKeyVault = (yield call(
                            [this.configService, this.configService.getSetting],
                            "KioskBlueDotLocationConfig"
                        )) as string;
                        const kioskLocationsFromVault: IDeviceConfigKeyVaultResponse = JSON.parse(
                            kioskLocationsFromKeyVault as string
                        );
                        const deviceConfigFromKeyVault = kioskLocationsFromVault.kioskLocations.find(
                            (k) => k.hardwareId === deviceConfig?.hardwareId
                        );
                        if (!pointsOfInterest[currentFloorId]) {
                            pointsOfInterest[currentFloorId] = {};
                        }
                        pointsOfInterest[currentFloorId].KioskLocation = undefined;
                        if (deviceConfigFromKeyVault && deviceConfigFloorId && deviceConfigFloorId === currentFloorId) {
                            pointsOfInterest[currentFloorId].KioskLocation = [
                                {
                                    longitude: deviceConfigFromKeyVault.longitude,
                                    latitude: deviceConfigFromKeyVault.latitude
                                }
                            ];
                        }
                        this.logger.logEvent("[KioskBlueDotLocation] Location retrieved from key vault ", {
                            deviceConfigFromKeyVault: JSON.stringify(deviceConfigFromKeyVault)
                        });
                    }
                }
            }
            yield put(pointsOfInterestRetrieved(buildingId, pointsOfInterest));
            yield put(stopTrackingEventAction(this.pointOfInterestRetrieveEvent));
        } catch (error) {
            this.logger.logError(error as Error, {
                BuildingId: buildingId,
                Message: "An error occurred while retrieving points of interests"
            });
        }
    }
    private *handleSetToDefaultBuilding(action: DefaultBuildingSelectedAction): Generator {
        try {
            const defaultBuildingId = (yield call(
                [this.configService, this.configService.getSetting],
                "DefaultUserBuildingId"
            )) as string;
            const upn = action.payload.upn ?? "";
            const targetBuildingName = action.payload.buildingName;
            const targetFloorName = action.payload.floorName;
            const targetRoomName = action.payload.roomName;
            const env = (yield call([this.configService, this.configService.getSetting], "Environment")) as string;
            // Validate if the DeepLinking is found from dip response and has valid dtId
            if (targetBuildingName) {
                const buildingQuery =
                    env === "Production"
                        ? QueryBuilder.from(Building)
                              .join(Building, AzureMapData, (b) => b.hasAzureMapData)
                              .join(Building, BuildingType, (b) => b.isOfBuildingType)
                              .addSelect(AzureMapData)
                              .addSelect(BuildingType)
                              .where((q) => q.compare(BuildingType, (bt) => bt.description.notEquals("Parking Garage")))
                              .where((q) => q.compare(Building, (b) => b.status.notEquals("Inactive")))
                              .where((q) => q.compare(Building, (b) => b.name.in(this.v2MapBuildings)))
                              .where((q) => q.compare(Building, (b) => b.name.equals(targetBuildingName)))
                        : QueryBuilder.from(Building)
                              .join(Building, AzureMapData, (b) => b.hasAzureMapData)
                              .addSelect(AzureMapData)
                              .where((q) => q.compare(Building, (b) => b.name.in(this.v2MapBuildings)))
                              .where((q) => q.compare(Building, (b) => b.name.equals(targetBuildingName)));
                const buildingResponse = (yield dipSagas.get(getTwins(buildingQuery))) as IDipResponse<Building[]>;
                this.checkDipError(
                    buildingResponse,
                    "[DIP Response] No building data available",
                    "Failed to retrieve the deep link building"
                );

                if (buildingResponse.data.length > 0 && buildingResponse.data[0].$dtId) {
                    const targetBuildingId = buildingResponse.data[0].$dtId;
                    if (targetRoomName) {
                        const spaceQuery = QueryBuilder.from(Space).where((q) =>
                            q.compare(Space, (s) => s.roomKey.equals(`${targetBuildingName}/${targetRoomName}`))
                        );
                        const spaceResponse = (yield dipSagas.get(getTwins(spaceQuery))) as IDipResponse<Space[]>;
                        this.checkDipError(
                            spaceResponse,
                            "[DIP Response] No deep link space data available",
                            "Failed to retrieve the deep link space"
                        );

                        if (spaceResponse.data.length > 0) {
                            const targetSpace = spaceResponse.data[0] as IAzureDigitalTwinV3SpaceRetrieve;
                            if (targetSpace.$dtId && targetSpace.floorId) {
                                yield put(setDeepLinkFloor(targetSpace.floorId));
                                yield put(setDeepLinkRoom(targetSpace.$dtId));
                            }
                        }
                    } else if (targetFloorName) {
                        const floorQuery = QueryBuilder.from(Floor).where((q) =>
                            q.compare(Floor, (f) => f.roomKey.equals(`${targetBuildingName}/${targetFloorName}`))
                        );
                        const floorResponse = (yield dipSagas.get(getTwins(floorQuery))) as IDipResponse<Floor[]>;
                        this.checkDipError(
                            floorResponse,
                            "[DIP Response] No deep link floor data available",
                            "Failed to retrieve the deep link floor"
                        );

                        if (floorResponse.data.length > 0) {
                            const targetFloor = floorResponse.data[0] as IAzureDigitalTwinV3SpaceRetrieve;
                            if (targetFloor.$dtId) {
                                yield put(setDeepLinkFloor(targetFloor.$dtId));
                            }
                        }
                    }
                    yield put(setBuilding(targetBuildingId));
                    return;
                }
            }
            // If the DeepLinking is not triggered or not found from dip response, continue using user assigned building
            const query = QueryBuilder.from(Employee)
                .where((q) => q.compare(Employee, (e) => e.userPrincipalName.equals(upn)))
                .join(Employee, Space, (e) => e.isInSpace)
                .addSelect(Space);
            const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Employee[]>;
            this.checkDipError(response, "[DIP Response] No user data available", "Failed to retrieve the signed user");

            let person = null;
            if (response.data.length > 0) {
                const dipPersonData = AdtConverters.formatIntoSmartUser(
                    response.data[0] as IAzureDigitalTwinV3UserRetrieve
                );

                person = (yield call(
                    [this.peopleService, this.peopleService.getPersonByUpn],
                    upn,
                    dipPersonData
                )) as IPerson | null;
            }

            const buildingIdToSet = (yield call(this.setUserDefaultBuilding, defaultBuildingId, person)) as {
                buildingId: string;
            };
            yield put(setBuilding(buildingIdToSet.buildingId));
        } catch (error) {
            this.logger.logError(error as Error, {
                Message:
                    "Failed to get PersonData from People service or DefaultUserBuildingId from Configuration Service " +
                    (error as Error).message
            });
        }
    }

    private *setUserDefaultBuilding(defaultBuildingId: string, person: IPerson | null): Generator {
        const userBuildingIdToSet = person && person.buildingId ? person.buildingId : defaultBuildingId;
        try {
            const query = QueryBuilder.from(Building)
                .join(Building, AzureMapData, (b) => b.hasAzureMapData)
                .addSelect(AzureMapData)
                .where((q) => q.compare(Building, (b) => b.name.in(this.v2MapBuildings)))
                .where((q) => q.compare(Building, (b) => b.$dtId.equals(userBuildingIdToSet)));
            const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Building[]>;
            this.checkDipError(
                response,
                "[DIP Response] No building data available",
                "Failed to retrieve the signed user building"
            );

            const buildingData = response.data;
            if (Object.keys(buildingData).length === 0) {
                return { buildingId: defaultBuildingId };
            } else {
                return { buildingId: userBuildingIdToSet };
            }
        } catch (error) {
            this.logger.logError(error as Error, {
                Message: "Failed to get buildingData from SpaceRepo service" + (error as Error).message
            });
        }
        return { buildingId: defaultBuildingId };
    }

    private formatBaseRoomData(floorId: string, space: SmartSpace): IRoomInfo {
        return {
            id: space.dtId,
            name: space.name,
            type: isHotDeskingEnabled(space) ? RoomSubTypes.HotDeskingRoom : (space.type as RoomSubTypes),
            cardAttributes: {
                id: space.dtId,
                spaceName: space.name,
                floorId: space.floorId ?? floorId,
                featureId: space.featureId,
                friendlyName: space.friendlyName
            }
        };
    }

    private generateRoomTypeWhitelist(blob: boolean): Set<RoomSubTypes> {
        return blob
            ? new Set<RoomSubTypes>([
                  RoomSubTypes.Lounge,
                  RoomSubTypes.KitchenetteVending,
                  RoomSubTypes.OpenMeetingArea,
                  RoomSubTypes.RetailShop,
                  RoomSubTypes.WellnessRelaxationRoom,
                  RoomSubTypes.MeditationMultiFaithRoom,
                  RoomSubTypes.RecreationGaming,
                  RoomSubTypes.Library,
                  RoomSubTypes.PatioTerrace,
                  RoomSubTypes.DiningSeating,
                  RoomSubTypes.DiningServery,
                  RoomSubTypes.ITSupport
              ])
            : new Set<RoomSubTypes>([
                  RoomSubTypes.ConferenceRoom,
                  RoomSubTypes.FocusRoom,
                  RoomSubTypes.ProjectRoom,
                  RoomSubTypes.MultiPurposeRoom
              ]);
    }

    private checkDipError(res: IDipResponse<BasicDigitalTwin[]>, loggerTitle?: string, throwErrMsg?: string): void {
        if (res.error) {
            this.logger.logError(new Error(loggerTitle ?? "[DIP Response Error]"), {
                error: res.error,
                status: res.status,
                message: res.statusText
            });
            throw new Error(throwErrMsg ?? res.error.toString());
        }
    }
}
