import { layer } from "azure-maps-control";
import { LayerIds, MapFeatureProperty } from "../../Constants/Identifiers";
import { busynessColorMapping, busynessLayerMinZoom, getIconTextOptions, getLabelTextOptions, iconLayerIconOptions, labelLayerIconOptions, labelTextOptions } from "./BusynessMapLayer.Constants";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
import { setLayerTextSpacingProperty } from "../MapLayerUtilities";
export class BusynessMapLayer {
    constructor(map, dataSource) {
        this.map = map;
        this.dataSource = dataSource;
        this.onFloorChange = (floorId) => {
            this.iconBusynessLayer.setOptions({ filter: this.getBusynessLayerFilter(floorId, true) });
            this.labelBusynessLayer.setOptions({ filter: this.getBusynessLayerFilter(floorId, false) });
        };
        this.onThemeChange = (theme) => {
            this.iconBusynessLayer.setOptions({ textOptions: getIconTextOptions(theme) });
            this.labelBusynessLayer.setOptions({ textOptions: getLabelTextOptions(theme) });
            this.dataSource.getShapes().forEach((shape) => {
                if (shape.getProperties().busynessStatusColor) {
                    const newColor = busynessColorMapping[shape.getProperties().busynessStatusColor];
                    if (newColor) {
                        shape.setProperties(Object.assign(Object.assign({}, shape.getProperties()), { busynessStatusColor: newColor }));
                    }
                }
            });
        };
        this.getBusynessLayerFilter = (floorId, hasIcon) => {
            return [
                "all",
                ["has", "busynessStatus"],
                ["==", ["has", "mapIcon"], hasIcon],
                ["==", ["get", MapFeatureProperty.FloorId], floorId]
            ];
        };
        this.iconBusynessLayer = new layer.SymbolLayer(this.dataSource, LayerIds.BusynessIconLayer, {
            iconOptions: iconLayerIconOptions,
            textOptions: labelTextOptions,
            minZoom: busynessLayerMinZoom,
            filter: this.getBusynessLayerFilter("", true)
        });
        this.labelBusynessLayer = new layer.SymbolLayer(this.dataSource, LayerIds.BusynessLabelLayer, {
            iconOptions: labelLayerIconOptions,
            textOptions: labelTextOptions,
            minZoom: busynessLayerMinZoom,
            filter: this.getBusynessLayerFilter("", false)
        });
        setLayerTextSpacingProperty(this.map, [LayerIds.BusynessIconLayer, LayerIds.BusynessLabelLayer]);
        this.map.layers.add([this.iconBusynessLayer, this.labelBusynessLayer], LayerIds.SpacePinsTailLayer);
        this.subscribeToEvents();
    }
    show() {
        this.iconBusynessLayer.setOptions({ visible: true });
        this.labelBusynessLayer.setOptions({ visible: true });
    }
    hide() {
        this.iconBusynessLayer.setOptions({ visible: false });
        this.labelBusynessLayer.setOptions({ visible: false });
    }
    subscribeToEvents() {
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorChange);
        MapEventsEmitter.getInstance().on("themeChanged", this.onThemeChange);
    }
}
