import { data, layer } from "azure-maps-control";
import { RouteDataProperty } from "./RouteLayer.Types";
import { LayerIds, MapLayerIds } from "../../Constants/Identifiers";
import { getDestinationRoute, getInitialRoute } from "./RouteLayerUtilities";
import { panCameraBoundsPadding, panCameraZoomStep, routeClickablePinsIconOptions, routeClickablePinsTextOptions, routePinsIconOptions, routeSegmentColor, routeSegmentWidth } from "./RouteLayer.Constants";
import { InternalMapControlContainer } from "../../Controls";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
import { indoorLayerMinZoom } from "../MapLayer.Constants";
export class RouteLayer {
    constructor(map, routeDataSource, routePinsDataSource, routeClickablePinsDataSource) {
        this.map = map;
        this.routeDataSource = routeDataSource;
        this.routePinsDataSource = routePinsDataSource;
        this.routeClickablePinsDataSource = routeClickablePinsDataSource;
        this.destinationFloorId = undefined;
        this.currentFloorId = undefined;
        this.routeFloorIds = undefined;
        this.onRouteLayerRendered = (routeData) => {
            var _a;
            if (routeData) {
                try {
                    (_a = InternalMapControlContainer.getInstance().indoorMapControl) === null || _a === void 0 ? void 0 : _a.goToFloor(getInitialRoute(routeData).floorId);
                    this.destinationFloorId = getDestinationRoute(routeData).floorId;
                    this.routeFloorIds = new Set(routeData.floorRoutes.map((route) => route.floorId));
                    MapEventsEmitter.getInstance().emit("layerActive", MapLayerIds.RouteLayer);
                    this.panCameraToRoute();
                }
                catch (error) {
                    console.error(`AzureMaps-RouteLayer: Error onRouteLayerRendered`, {
                        routeData,
                        error
                    });
                }
            }
            else {
                MapEventsEmitter.getInstance().emit("layerInactive", MapLayerIds.RouteLayer);
                this.destinationFloorId = undefined;
                this.routeFloorIds = undefined;
            }
        };
        this.onFloorChange = (floorId) => {
            this.currentFloorId = floorId;
            this.routePinsLayer.setOptions({ filter: this.getRouteLayerFilter(floorId) });
            this.routeClickablePinsLayer.setOptions({ filter: this.getRouteLayerFilter(floorId) });
            this.routeSegmentsLayer.setOptions({ filter: this.getRouteLayerFilter(floorId) });
            this.panCameraToRoute();
        };
        this.onClickablePinsClick = (event) => {
            setTimeout(() => {
                var _a;
                if (this.destinationFloorId) {
                    (_a = InternalMapControlContainer.getInstance().indoorMapControl) === null || _a === void 0 ? void 0 : _a.goToFloor(this.destinationFloorId);
                }
            }, 50);
        };
        this.panCameraToRoute = () => {
            var _a;
            if (this.routeFloorIds === undefined || this.currentFloorId === undefined)
                return;
            const positions = this.routeFloorIds.has(this.currentFloorId)
                ? this.getCurrentFloorSegmentPositions()
                : this.getCurrentFloorClickablePinPositions();
            if (positions.length === 0)
                return;
            this.map.setCamera({
                bounds: data.BoundingBox.fromPositions(positions),
                padding: panCameraBoundsPadding
            });
            const currentZoom = (_a = this.map.getCamera().zoom) === null || _a === void 0 ? void 0 : _a.valueOf();
            if (currentZoom) {
                this.map.setCamera({ zoom: currentZoom - panCameraZoomStep });
            }
        };
        this.getCurrentFloorClickablePinPositions = () => {
            return this.routeClickablePinsDataSource
                .getShapes()
                .filter((shape) => shape.getProperties()[RouteDataProperty.FloorId] === this.currentFloorId)
                .map((shape) => shape.getCoordinates());
        };
        this.getCurrentFloorSegmentPositions = () => {
            return this.routeDataSource
                .getShapes()
                .filter((shape) => shape.getProperties()[RouteDataProperty.FloorId] === this.currentFloorId)
                .reduce((acc, each) => acc.concat(each.getCoordinates()), []);
        };
        this.getRouteLayerFilter = (floorId) => {
            return ["==", ["get", RouteDataProperty.FloorId], floorId];
        };
        this.mouseoverCursor = () => {
            this.map.getCanvasContainer().style.cursor = "pointer";
        };
        this.mouseoutCursor = () => {
            this.map.getCanvasContainer().style.cursor = "grab";
        };
        this.routeSegmentsLayer = new layer.LineLayer(this.routeDataSource, LayerIds.RouteSegmentsLayer, {
            strokeColor: routeSegmentColor,
            strokeWidth: routeSegmentWidth,
            minZoom: indoorLayerMinZoom,
            filter: this.getRouteLayerFilter("")
        });
        this.routePinsLayer = new layer.SymbolLayer(this.routePinsDataSource, LayerIds.RoutePinsLayer, {
            iconOptions: routePinsIconOptions,
            minZoom: indoorLayerMinZoom,
            filter: this.getRouteLayerFilter("")
        });
        this.routeClickablePinsLayer = new layer.SymbolLayer(this.routeClickablePinsDataSource, LayerIds.RouteClickablePinsLayer, {
            iconOptions: routeClickablePinsIconOptions,
            textOptions: routeClickablePinsTextOptions,
            minZoom: indoorLayerMinZoom,
            filter: this.getRouteLayerFilter("")
        });
        this.map.layers.add([this.routeSegmentsLayer, this.routePinsLayer, this.routeClickablePinsLayer]);
        this.subscribeToEvents();
    }
    show() {
        this.routeSegmentsLayer.setOptions({ visible: true });
        this.routePinsLayer.setOptions({ visible: true });
        this.routeClickablePinsLayer.setOptions({ visible: true });
    }
    hide() {
        this.routeSegmentsLayer.setOptions({ visible: false });
        this.routePinsLayer.setOptions({ visible: false });
        this.routeClickablePinsLayer.setOptions({ visible: false });
    }
    subscribeToEvents() {
        this.map.events.add("click", this.routeClickablePinsLayer, this.onClickablePinsClick);
        this.map.events.add("mouseover", this.routeClickablePinsLayer, this.mouseoverCursor);
        this.map.events.add("mouseout", this.routeClickablePinsLayer, this.mouseoutCursor);
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorChange);
        MapEventsEmitter.getInstance().on("routeLayerRendered", this.onRouteLayerRendered);
    }
}
