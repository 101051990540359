import { indoorLayerMinZoom, textColorsDark, textColorsLight } from "../MapLayer.Constants";
import { MapFeatureProperty } from "../../Constants/Identifiers";
import { MapTheme } from "../../Components/AzureMapComponent.Types";
const textSizeExpression = [
    "interpolate",
    ["linear"],
    ["zoom"],
    18,
    5,
    18.5,
    6.5,
    19,
    8,
    19.5,
    9.5,
    20,
    11
];
const textFieldExpression = [
    "format",
    ["get", MapFeatureProperty.Name],
    "\n",
    {},
    ["get", "busynessStatus"],
    {
        "text-color": ["get", "busynessStatusColor"]
    }
];
export const iconSizeExpression = ["interpolate", ["linear"], ["zoom"], indoorLayerMinZoom, 0.7, 21, 1.1];
export const iconLayerIconOptions = {
    image: ["get", "mapIcon"],
    anchor: "bottom",
    size: iconSizeExpression
};
export const labelLayerIconOptions = { image: "none" };
export const iconTextOptions = {
    textField: textFieldExpression,
    anchor: "top",
    offset: [0, -0.1],
    font: ["SegoeFrutigerHelveticaMYingHei-Medium"],
    haloBlur: 0.5,
    haloWidth: 1,
    haloColor: textColorsDark.haloColor,
    size: textSizeExpression,
    color: textColorsDark.color
};
export const labelTextOptions = {
    textField: textFieldExpression,
    anchor: "center",
    font: ["SegoeUi-SemiBold"],
    haloBlur: 0.5,
    haloWidth: 1,
    haloColor: textColorsDark.haloColor,
    size: textSizeExpression,
    color: textColorsDark.color
};
export const busynessLayerMinZoom = 17.5;
export const busynessColorMapping = {
    "#92C353": "#59960a",
    "#D74553": "#fA2237",
    "#C56653": "#fa431e",
    "#FFFFFF": "#000000",
    transparent: "transparent",
    "#59960a": "#92C353",
    "#fA2237": "#D74553",
    "#fa431e": "#C56653",
    "#000000": "#FFFFFF"
};
export function getLabelTextOptions(theme) {
    if (theme === MapTheme.Dark) {
        return labelTextOptions;
    }
    return Object.assign(Object.assign({}, labelTextOptions), { haloColor: textColorsLight.haloColor, color: textColorsLight.color });
}
export function getIconTextOptions(theme) {
    if (theme === MapTheme.Dark) {
        return iconTextOptions;
    }
    return Object.assign(Object.assign({}, iconTextOptions), { haloColor: textColorsLight.haloColor, color: textColorsLight.color });
}
