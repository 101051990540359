import { LayerIds } from "../Constants/Identifiers";
import { EventEmitter } from "tseep";
import { mapThemes } from "../Types/MapStyles";
export class MapEventsEmitter {
    constructor() { }
    static getInstance() {
        if (!MapEventsEmitter.instance) {
            MapEventsEmitter.instance = new EventEmitter();
        }
        return MapEventsEmitter.instance;
    }
    static setFeatureIdDtIDMap(mapping) {
        MapEventsEmitter.featureIdDtIDMap = mapping;
    }
    static setUpClickSubscription(map, azureMapsContext) {
        map.events.add("click", (event) => {
            var _a, _b, _c, _d, _e, _f;
            const nonDefaultClickFeatures = event.map.layers.getRenderedShapes(event.position, [
                LayerIds.SpacePinsTopLayer,
                LayerIds.SpacePinsTailLayer,
                LayerIds.RoutePinsLayer,
                LayerIds.RouteClickablePinsLayer
            ]);
            if (nonDefaultClickFeatures.length > 0)
                return;
            const shapes = event.map.layers.getRenderedShapes(event.position, undefined, [
                "all",
                ["has", "azureMapsFeatureId"],
                ["has", "name"]
            ]);
            if (shapes.length > 0) {
                const featureId = (_b = (_a = shapes[0].properties) === null || _a === void 0 ? void 0 : _a.azureMapsFeatureId) !== null && _b !== void 0 ? _b : "";
                const spaceData = {
                    originalId: this.featureIdDtIDMap[featureId],
                    name: (_d = (_c = shapes[0].properties) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "",
                    featureId: (_f = (_e = shapes[0].properties) === null || _e === void 0 ? void 0 : _e.azureMapsFeatureId) !== null && _f !== void 0 ? _f : ""
                };
                azureMapsContext.setCurrentSpaceData(spaceData);
            }
        });
    }
    static setUpThemeSubscription(map) {
        map.events.add("stylechanged", (e) => {
            const style = e.style;
            for (const value of Object.values(mapThemes)) {
                if (value.indoor === style || value.outdoor === style) {
                    MapEventsEmitter.getInstance().emit("themeChanged", value.theme);
                }
            }
        });
    }
}
MapEventsEmitter.featureIdDtIDMap = {};
