var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Building, BuildingAdministration, BuildingOccupancy, BuildingType, Location, OwnershipType, SpaceStatus } from "@dip/adt-types";
import { convertDipToFeatureCollection } from "./DataAdapters";
import axios from "axios";
import { PoiType } from "../Constants/Identifiers";
import { QueryBuilder } from "@dip/querybuilder";
export class HttpService {
    constructor(requestInterceptors) {
        this.axiosInstance = axios.create();
        requestInterceptors.forEach((interceptor) => {
            this.axiosInstance.interceptors.request.use(interceptor);
        });
    }
    static getInstance(requestInterceptors) {
        if (!HttpService.instance) {
            HttpService.instance = new HttpService(requestInterceptors);
        }
        return HttpService.instance;
    }
    getBuildingOverlayData() {
        return __awaiter(this, void 0, void 0, function* () {
            const ownershipTypes = ["Leased", "Owned"];
            const buildingTypes = ["Business Use", "Office", "R&D", "Serviced Office", "Cafeteria"];
            const dipQuery = QueryBuilder.from(Building)
                .join(Building, BuildingType, (b) => b.isOfBuildingType)
                .join(Building, OwnershipType, (b) => b.hasOwnershipType)
                .join(Building, Location, (b) => b.hasLocation)
                .join(Building, BuildingAdministration, (b) => b.hasBuildingAdministration)
                .join(BuildingAdministration, BuildingOccupancy, (ba) => ba.hasBuildingOccupancy)
                .where((q) => q.compare(Building, (b) => b.status.equals(SpaceStatus.Active)))
                .where((q) => q.compare(OwnershipType, (ot) => ot.name.in(ownershipTypes)))
                .where((q) => q.compare(BuildingOccupancy, (bo) => bo.occupiedArea.gt(0)))
                .where((q) => q.compare(BuildingType, (bt) => bt.description.in(buildingTypes)))
                .addSelect(OwnershipType)
                .addSelect(BuildingType)
                .addSelect(BuildingAdministration)
                .addSelect(BuildingOccupancy)
                .addSelect(Location);
            const dipPostBody = { query: dipQuery.buildAdtQuery() };
            return this.axiosInstance
                .post("https://dwp-nonprod.azure-api.net/dip/query/v3.0/api/query?api-version=2022-09-01-preview", dipPostBody)
                .then((res) => res.data)
                .then((data) => {
                return convertDipToFeatureCollection(data);
            });
        });
    }
    getData(id) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!id) {
                return undefined;
            }
            return this.axiosInstance
                .get(`https://sbs-dev-smartbuildingapi-westus.azurewebsites.net/api/v1.0/spaces/mapData/v2/${id}?includePOI=true`)
                .then((res) => this.addMapIconProp(res.data))
                .then((data) => this.filterPOIData(data))
                .catch(() => {
                return undefined;
            });
        });
    }
    addMapIconProp(data) {
        const categoryToMapIconMap = {
            Lounge: "lounge",
            KitchenetteVending: "vending"
        };
        data.features.features.forEach((feature, index) => {
            const { category } = feature.properties;
            if (categoryToMapIconMap[category]) {
                feature.properties.mapIcon = categoryToMapIconMap[category];
                data.features.features[index] = feature;
            }
        });
        return data;
    }
    getAzureMapToDipMapping(datasetId, buildingId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!datasetId || !buildingId) {
                return {};
            }
            return this.axiosInstance
                .get(`https://sbs-dev-smartbuildingapi-westus.azurewebsites.net/api/v1.0/spaces/azureMapToDipMapping/${datasetId}/${buildingId}`)
                .then((res) => res.data)
                .catch(() => {
                return {};
            });
        });
    }
    filterPOIData(data) {
        const poiTypes = Object.values(PoiType);
        const filteredPOIFeatures = data.poi.features.filter((poi) => poiTypes.includes(poi.properties.poiType));
        data.poi.features = filteredPOIFeatures;
        return data;
    }
}
