import React, { createContext, useContext, useState } from "react";
export const AzureMapsContext = createContext({
    mapRef: null,
    isMapReady: false,
    isIndoorMapReady: false,
    isMapDataReady: false,
    currentBuildingId: null,
    currentFloorId: null,
    currentSpaceData: null,
    mapControl: null,
    indoorMapControl: null,
    mapDataControl: null,
    mapLayerControl: null,
    setMapRef: () => { },
    removeMapRef: () => { },
    setMapReady: () => { },
    setIndoorMapReady: () => { },
    setMapDataReady: () => { },
    setCurrentBuildingId: () => { },
    setMapControl: () => { },
    setIndoorMapControl: () => { },
    setCurrentFloorId: () => { },
    setMapDataControl: () => { },
    setMapLayerControl: () => { },
    setCurrentSpaceData: () => { }
});
export const AzureMapsProvider = ({ children }) => {
    const [mapRef, setMapRef] = useState(null);
    const [isMapReady, setIsMapReady] = useState(false);
    const [isIndoorMapReady, setIsIndoorMapReady] = useState(false);
    const [isMapDataReady, setIsMapDataReady] = useState(false);
    const [currentBuildingId, setCurrentBuildingId] = useState(null);
    const [currentFloorId, setCurrentFloorId] = useState(null);
    const [currentSpaceData, setCurrentSpaceData] = useState(null);
    const [indoorMapControl, setIndoorMapControl] = useState(null);
    const [mapControl, setMapControl] = useState(null);
    const [mapDataControl, setMapDataControl] = useState(null);
    const [mapLayerControl, setMapLayerControl] = useState(null);
    return (React.createElement(AzureMapsContext.Provider, { value: {
            mapRef,
            setMapRef,
            isMapReady,
            setMapReady: setIsMapReady,
            isIndoorMapReady,
            setIndoorMapReady: setIsIndoorMapReady,
            isMapDataReady,
            setMapDataReady: setIsMapDataReady,
            currentBuildingId,
            setCurrentBuildingId,
            currentFloorId,
            setCurrentFloorId,
            currentSpaceData,
            setCurrentSpaceData,
            removeMapRef: () => setMapRef(null),
            mapControl: mapControl,
            setMapControl,
            indoorMapControl: indoorMapControl,
            setIndoorMapControl,
            mapDataControl: mapDataControl,
            setMapDataControl,
            mapLayerControl: mapLayerControl,
            setMapLayerControl
        } }, children));
};
export const useAzureMapsContext = () => useContext(AzureMapsContext);
