import { HtmlMarker, data } from "azure-maps-control";
import { IconSvgCreator } from "../../Utilities/IconSvgCreator";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
import { RouteIconLabels } from "../../Assets";
export class KioskBlueDotLayer {
    constructor(map) {
        this.map = map;
        this.onFloorIdChange = (floorId) => {
            if (floorId === this.kioskFloorId) {
                this.show();
            }
            else {
                this.hide();
            }
        };
        const kioskSvg = new IconSvgCreator().createRouteMarkerSvg(RouteIconLabels.Start);
        this.kioskMarker = new HtmlMarker({
            htmlContent: kioskSvg.svg,
            visible: false,
            anchor: "bottom",
            draggable: false,
            pixelOffset: [0, kioskSvg.heightOffset]
        });
        this.map.markers.add(this.kioskMarker);
        this.subscribeToEvents();
    }
    show() {
        this.kioskMarker.setOptions({ visible: true });
    }
    hide() {
        this.kioskMarker.setOptions({ visible: false });
    }
    setKioskData(kioskData) {
        this.kioskFloorId = kioskData.kioskFloorId;
        const kioskCoordinates = new data.Position(kioskData.kioskLng, kioskData.kioskLat);
        this.kioskMarker.setOptions({
            position: kioskCoordinates,
            visible: true
        });
        this.map.setCamera({
            center: kioskCoordinates,
            bearing: kioskData.kioskBearing
        });
    }
    subscribeToEvents() {
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorIdChange);
    }
}
