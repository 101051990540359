import "azure-maps-control/dist/atlas.css";
import "azure-maps-indoor/dist/atlas-indoor.css";
import "../Styles/AzureMap.css";
import { AuthenticationType, Map, data as azMapData } from "azure-maps-control";
import { DeviceType } from "./AzureMapComponent.Types";
import { IndoorMapControl, InternalMapControlContainer, MapControl, MapDataControl, MapLayerControl } from "../Controls";
import React, { useEffect, useRef } from "react";
import { createOrdinalFloorDataMap, findFloorIdByOrdinal } from "../Utilities/FeatureCollectionUtilities";
import { loadOutdoorMapIcons, loadPOIIcons, loadRoutePinIcons, loadSpaceTypeIcons } from "../Utilities/MapImagesUtilities";
import { AzureMapUtilities } from "../Utilities/AzureMapUtilities";
import { DataSourcesManager } from "../Managers/DataSourcesManager";
import { MapEventsEmitter } from "../Services/MapEventsEmitter";
import { MapLayersManager } from "../Managers/MapLayersManager";
import { indoor } from "azure-maps-indoor";
import { mapThemes } from "../Types/MapStyles";
import { useAzureMapsContext } from "../Contexts/AzureMapsContext";
import { useOutdoorMap } from "./Hooks/useOutdoorMap";
import { useQuery } from "react-query";
const dataSourcesManager = new DataSourcesManager();
const eventEmitter = MapEventsEmitter.getInstance();
export function AzureMapComponent(props) {
    const mapRef = useRef(null);
    const indoorMapRef = useRef(null);
    const ordinalFloorDataMap = useRef({});
    const { buildingId, mapData, initialFloorId, clientId, theme, mapTokenCallback, httpService, config } = props;
    const azureMapsContext = useAzureMapsContext();
    const { data: azureMapsData } = useQuery({
        queryKey: ["mapData", buildingId !== null && buildingId !== void 0 ? buildingId : ""],
        queryFn: () => httpService.getData(buildingId),
        staleTime: Infinity,
        enabled: !!buildingId
    });
    const { data: buildingOverlayData } = useQuery({
        queryKey: ["mapData", "buildingOverlayData"],
        queryFn: () => httpService.getBuildingOverlayData(),
        staleTime: Infinity,
        enabled: config === DeviceType.Web
    });
    const { data: amDipMapping } = useQuery({
        queryKey: ["mapData", mapData === null || mapData === void 0 ? void 0 : mapData.datasetId, buildingId],
        queryFn: () => httpService.getAzureMapToDipMapping(mapData === null || mapData === void 0 ? void 0 : mapData.datasetId, buildingId),
        staleTime: Infinity,
        enabled: true
    });
    useEffect(() => {
        if (amDipMapping) {
            MapEventsEmitter.setFeatureIdDtIDMap(amDipMapping);
        }
    }, [amDipMapping]);
    useEffect(() => {
        if (!clientId) {
            return;
        }
        if (mapRef.current === null) {
            mapRef.current = new Map("azureMap", {
                showLogo: false,
                showFeedbackLink: false,
                disableTelemetry: true,
                showLabels: false,
                progressiveLoading: true,
                style: mapThemes[theme].outdoor,
                domain: "us.atlas.microsoft.com",
                authOptions: {
                    authType: AuthenticationType.anonymous,
                    clientId: clientId,
                    getToken: (resolve) => {
                        mapTokenCallback().then((token) => resolve(token));
                    }
                }
            });
            AzureMapUtilities.deviceType = config;
            mapRef.current.events.add("ready", () => {
                if (!mapRef.current)
                    return;
                dataSourcesManager.initialize(mapRef.current);
                azureMapsContext.setMapDataControl(new MapDataControl(dataSourcesManager));
                loadSpaceTypeIcons(mapRef.current);
                loadPOIIcons(mapRef.current);
                loadRoutePinIcons(mapRef.current);
                loadOutdoorMapIcons(mapRef.current);
                const mapLayersManager = new MapLayersManager(mapRef.current, dataSourcesManager, config);
                azureMapsContext.setMapLayerControl(new MapLayerControl(mapLayersManager));
                azureMapsContext.setMapRef(mapRef.current);
                azureMapsContext.setMapControl(new MapControl(mapRef.current));
                azureMapsContext.setMapReady(true);
            });
            if (mapRef.current)
                MapEventsEmitter.setUpThemeSubscription(mapRef.current);
        }
    }, [clientId]);
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        const tilesetId = mapData === null || mapData === void 0 ? void 0 : mapData.tilesetId;
        const configurationId = mapData === null || mapData === void 0 ? void 0 : mapData.mapConfigurationId;
        if (!mapRef.current || !azureMapsContext.isMapReady || !tilesetId || !configurationId)
            return;
        mapRef.current.setServiceOptions({
            mapConfiguration: configurationId,
            styleAPIVersion: "2023-03-01-preview"
        });
        mapRef.current.events.addOnce("stylechanged", () => {
            var _a;
            (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.setStyle({ style: mapThemes[theme].indoor });
        });
        if (!azureMapsData) {
            azureMapsContext.setMapDataReady(false);
            ordinalFloorDataMap.current = {};
            return;
        }
        azureMapsContext.setMapDataReady(true);
        ordinalFloorDataMap.current = createOrdinalFloorDataMap(azureMapsData.level);
        if (indoorMapRef.current === null) {
            indoorMapRef.current = new indoor.IndoorManager(mapRef.current, {
                tilesetId: tilesetId
            });
            mapRef.current.events.add("levelchanged", indoorMapRef.current, (event) => {
                const floorData = ordinalFloorDataMap.current[event.levelNumber - 1];
                if (floorData.floorId) {
                    azureMapsContext.setCurrentFloorId(floorData.floorId);
                    eventEmitter.emit("floorIdChanged", floorData.floorId);
                }
            });
            if (mapRef.current)
                MapEventsEmitter.setUpClickSubscription(mapRef.current, azureMapsContext);
            const indoorMapControl = new IndoorMapControl(mapRef.current, indoorMapRef.current, dataSourcesManager);
            azureMapsContext.setIndoorMapControl(indoorMapControl);
            InternalMapControlContainer.map = mapRef.current;
            InternalMapControlContainer.getInstance().indoorMapControl = indoorMapControl;
        }
        else {
            indoorMapRef.current.setOptions({
                tilesetId: tilesetId
            });
        }
        dataSourcesManager.setFeaturesData(azureMapsData.features);
        dataSourcesManager.setLevelsData(azureMapsData.level);
        dataSourcesManager.setPointsOfInterestData(azureMapsData.poi);
        const initialFloor = dataSourcesManager.getLevelsDataSource().getShapeById(initialFloorId);
        const facilityId = (_a = azureMapsData.facility.properties) === null || _a === void 0 ? void 0 : _a.facilityId;
        if (initialFloor) {
            InternalMapControlContainer.setIndoorMapBounds(azMapData.BoundingBox.fromData(initialFloor), config);
            indoorMapRef.current.setFacility(`${tilesetId}:${facilityId}`, initialFloor.getProperties()["ordinal"]);
            azureMapsContext.setCurrentFloorId(initialFloorId);
            eventEmitter.emit("floorIdChanged", initialFloorId);
        }
        else {
            const facilityFeature = new azMapData.Feature(azureMapsData.facility.geometry, Object.assign({}, azureMapsData.facility.properties));
            InternalMapControlContainer.setIndoorMapBounds(azMapData.BoundingBox.fromData(facilityFeature), config);
            indoorMapRef.current.setFacility(`${tilesetId}:${facilityId}`, 0);
            const floorData = findFloorIdByOrdinal(azureMapsData.level, 0);
            azureMapsContext.setCurrentFloorId((_b = floorData === null || floorData === void 0 ? void 0 : floorData.floorId) !== null && _b !== void 0 ? _b : "");
            eventEmitter.emit("floorIdChanged", (_c = floorData === null || floorData === void 0 ? void 0 : floorData.floorId) !== null && _c !== void 0 ? _c : "");
        }
        azureMapsContext.setCurrentBuildingId((_e = (_d = azureMapsData.facility.properties) === null || _d === void 0 ? void 0 : _d.originalId) !== null && _e !== void 0 ? _e : "");
        const indoorReadyTimeout = setTimeout(() => {
            azureMapsContext.setIndoorMapReady(true);
            eventEmitter.emit("indoorMapReady", true);
        }, 1000);
        mapRef.current.events.add("indoorready", indoorMapRef.current, () => {
            clearTimeout(indoorReadyTimeout);
            azureMapsContext.setIndoorMapReady(true);
            eventEmitter.emit("indoorMapReady", true);
        });
    }, [azureMapsContext.isMapReady, mapData === null || mapData === void 0 ? void 0 : mapData.tilesetId, azureMapsData]);
    useOutdoorMap(mapRef.current, indoorMapRef.current, azureMapsContext.isMapReady, mapData === null || mapData === void 0 ? void 0 : mapData.tilesetId, dataSourcesManager, buildingOverlayData, theme);
    return (React.createElement("div", { className: "mapDiv", id: "azureMap", style: {
            visibility: azureMapsContext.isIndoorMapReady || config === DeviceType.Mobile ? "visible" : "hidden"
        } }));
}
