import { mapThemes } from "../../Types/MapStyles";
import { useEffect } from "react";
export function useOutdoorMap(map, indoorManager, ready, tilesetId, dataSourcesManager, buildingOverlayData, theme) {
    useEffect(() => {
        if (!ready || !buildingOverlayData)
            return;
        if (tilesetId) {
            dataSourcesManager.getBuildingOverlayDataSource().clear();
        }
        else {
            dataSourcesManager.setBuildingOverlayData(buildingOverlayData);
        }
    }, [ready, buildingOverlayData, tilesetId]);
    useEffect(() => {
        if (!map || !indoorManager || !ready || tilesetId !== undefined)
            return;
        dataSourcesManager.clearIndoorData();
        map.setServiceOptions({
            mapConfiguration: undefined,
            styleAPIVersion: undefined
        });
        map.events.addOnce("stylechanged", () => {
            map.setStyle({ style: mapThemes[theme].outdoor });
        });
    }, [map, indoorManager, ready, tilesetId]);
}
