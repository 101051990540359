import "../../Styles/PersonLayer.css";
import { HtmlMarker } from "azure-maps-control";
import { InternalMapControlContainer } from "../../Controls";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
import { getPersonLayerHtmlContent } from "./PersonLayer.Helper";
import polylabel from "polylabel";
import { polylabelPrecision } from "../MapLayer.Constants";
export class PersonLayer {
    constructor(map, featuresDataSource) {
        this.map = map;
        this.featuresDataSource = featuresDataSource;
        this.onFloorIdChange = (floorId) => {
            var _a;
            this.currentFloorId = floorId;
            if (this.currentFloorId === ((_a = this.personLayerData) === null || _a === void 0 ? void 0 : _a.floorId)) {
                this.show();
            }
            else {
                this.hide();
            }
        };
        this.goToTargetFloor = (targetFloorId) => {
            var _a;
            if (targetFloorId !== this.currentFloorId) {
                (_a = InternalMapControlContainer.getInstance().indoorMapControl) === null || _a === void 0 ? void 0 : _a.goToFloor(targetFloorId);
            }
        };
        this.renderPersonMarker = (position, displayName, imageString) => {
            this.personMarker.setOptions({
                position: position,
                htmlContent: getPersonLayerHtmlContent(displayName, imageString)
            });
        };
        this.panCameraToUserLocation = (position, centerOffset) => {
            this.map.setCamera({
                center: position,
                centerOffset: centerOffset,
                type: "ease",
                duration: 200
            });
        };
        this.personMarker = new HtmlMarker({
            visible: false
        });
        this.map.markers.add(this.personMarker);
        this.subscribeToEvents();
    }
    show() {
        this.personMarker.setOptions({ visible: true });
    }
    hide() {
        this.personMarker.setOptions({ visible: false });
    }
    setPersonLayerData(personLayerData) {
        if (personLayerData) {
            this.personLayerData = personLayerData;
            const targetShape = this.featuresDataSource.getShapeById(this.personLayerData.spaceId);
            if (targetShape) {
                this.goToTargetFloor(this.personLayerData.floorId);
                const coordinates = targetShape.getCoordinates();
                const position = polylabel(coordinates, polylabelPrecision);
                this.renderPersonMarker(position, this.personLayerData.displayName, this.personLayerData.imageBase64String);
                this.panCameraToUserLocation(position, this.personLayerData.cameraOffset);
                this.show();
            }
        }
        else {
            this.personLayerData = undefined;
            this.hide();
        }
    }
    subscribeToEvents() {
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorIdChange);
    }
}
