var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "../../Styles/PeopleOverlayLayer.css";
import { HtmlMarker } from "azure-maps-control";
import { extraPeopleBackground, extraPeopleColor, peopleOverlayMaxZoom, peopleOverlayMinZoom, personMarkerBorder } from "./PeopleOverlayLayer.Constants";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
import { MapTheme } from "../../Components/AzureMapComponent.Types";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { createMarkerDivElement } from "./PeopleOverlayLayer.Helper";
import { getDataSourceFeaturesInView } from "../../Utilities/DatasourceUtilities";
import polylabel from "polylabel";
import { polylabelPrecision } from "../MapLayer.Constants";
export class PeopleOverlayLayer {
    constructor(map, featuresDataSource) {
        this.map = map;
        this.featuresDataSource = featuresDataSource;
        this.processingFeatureIds = new Set();
        this.visibleFeatureIds = new Set();
        this.featureMarkerMap = {};
        this.featureImagesMap = {};
        this.passedFeatureImageMap = undefined;
        this.getFeatureImagesCallback = undefined;
        this.currentFloorId = "";
        this.isHidden = false;
        this.peopleOverlayFeatureDisabled = false;
        this.onFloorChange = (floorId) => {
            this.currentFloorId = floorId;
            this.renderLayer();
        };
        this.renderLayer = () => __awaiter(this, void 0, void 0, function* () {
            if (this.isHidden || this.peopleOverlayFeatureDisabled)
                return;
            this.visibleFeatureIds.clear();
            if (this.shouldShowPeopleOverlayLayer()) {
                const featuresWithPeople = getDataSourceFeaturesInView(this.map, this.featuresDataSource, this.currentFloorId, [
                    RoomSubTypes.TeamOffice,
                    RoomSubTypes.Office
                ]);
                const featureGeometryList = [];
                featuresWithPeople.forEach((feature) => {
                    var _a, _b, _c;
                    this.visibleFeatureIds.add((_a = feature.properties) === null || _a === void 0 ? void 0 : _a.originalId);
                    featureGeometryList.push({
                        featureId: (_c = (_b = feature.properties) === null || _b === void 0 ? void 0 : _b.originalId) !== null && _c !== void 0 ? _c : "",
                        geometry: feature.geometry
                    });
                });
                Object.keys(this.featureMarkerMap).forEach((featureId) => this.toggleMarkerVisibility(featureId, this.visibleFeatureIds.has(featureId)));
                featureGeometryList.forEach((featureGeometry) => {
                    if (!this.featureMarkerMap[featureGeometry.featureId])
                        this.renderFeatureMarker(featureGeometry);
                });
                this.toggleMarkerVisibility(this.featureIdToHide, false);
            }
            else {
                this.hideMarkers();
            }
        });
        this.onThemeChange = (theme) => {
            const currentTheme = this.map.getStyle().style;
            if (currentTheme) {
                Object.values(this.featureMarkerMap).forEach((marker) => {
                    const htmlDiv = marker.getElement();
                    if (htmlDiv) {
                        const imgElements = htmlDiv.getElementsByTagName("img");
                        for (const imgElement of imgElements) {
                            imgElement.style.border =
                                theme === MapTheme.Dark ? personMarkerBorder.dark : personMarkerBorder.light;
                        }
                        const extraPplDiv = htmlDiv.querySelector(".person-marker-extra-ppl-div");
                        if (extraPplDiv) {
                            extraPplDiv.style.backgroundColor =
                                theme === MapTheme.Dark ? extraPeopleBackground.dark : extraPeopleBackground.light;
                            extraPplDiv.style.color =
                                theme === MapTheme.Dark ? extraPeopleColor.dark : extraPeopleColor.light;
                            extraPplDiv.style.outline =
                                theme === MapTheme.Dark ? personMarkerBorder.dark : personMarkerBorder.light;
                        }
                    }
                    marker.setOptions({
                        htmlContent: htmlDiv
                    });
                });
            }
        };
        this.shouldShowPeopleOverlayLayer = () => {
            const zoomLevel = this.map.getCamera().zoom;
            return zoomLevel !== undefined && zoomLevel >= peopleOverlayMinZoom && zoomLevel <= peopleOverlayMaxZoom;
        };
        this.subscribeToEvents();
    }
    show() {
        this.isHidden = false;
        this.renderLayer();
    }
    hide() {
        this.isHidden = true;
        this.hideMarkers();
    }
    setFeatureImagesCallback(getFeatureImagesCallback, passedFeatureImageMap) {
        this.getFeatureImagesCallback = getFeatureImagesCallback;
        this.passedFeatureImageMap = passedFeatureImageMap;
    }
    hideMarkersByFeatureId(featureId) {
        if (featureId) {
            this.featureIdToHide = featureId;
            this.toggleMarkerVisibility(this.featureIdToHide, false);
        }
        else {
            if (this.shouldShowPeopleOverlayLayer()) {
                this.toggleMarkerVisibility(this.featureIdToHide, true);
            }
            this.featureIdToHide = undefined;
        }
    }
    setPeopleOverlayFeatureDisabled(peopleOverlayFeatureDisabled) {
        this.peopleOverlayFeatureDisabled = peopleOverlayFeatureDisabled;
        if (this.peopleOverlayFeatureDisabled) {
            this.hide();
        }
        else {
            this.show();
        }
    }
    subscribeToEvents() {
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorChange);
        MapEventsEmitter.getInstance().on("themeChanged", this.onThemeChange);
        this.map.events.add("moveend", this.renderLayer);
    }
    toggleMarkerVisibility(featureId, isVisible) {
        if (!featureId || !this.featureMarkerMap[featureId])
            return;
        this.featureMarkerMap[featureId].setOptions({ visible: isVisible });
    }
    hideMarkers() {
        Object.keys(this.featureMarkerMap).forEach((featureId) => {
            this.toggleMarkerVisibility(featureId, false);
        });
    }
    renderFeatureMarker(featureGeometry) {
        return __awaiter(this, void 0, void 0, function* () {
            const isProcessing = this.processingFeatureIds.has(featureGeometry.featureId);
            const marker = this.featureMarkerMap[featureGeometry.featureId];
            if (isProcessing || marker)
                return;
            let featureImagesToAdd = this.featureImagesMap[featureGeometry.featureId];
            if (!featureImagesToAdd) {
                if (this.passedFeatureImageMap && Object.keys(this.passedFeatureImageMap).length > 0) {
                    featureImagesToAdd = this.getFeatureImagesFromPassedMapByFeatureId(featureGeometry.featureId);
                }
                else if (this.getFeatureImagesCallback) {
                    this.processingFeatureIds.add(featureGeometry.featureId);
                    featureImagesToAdd = yield this.getFeatureImagesCallback(featureGeometry.featureId);
                }
            }
            this.addFeatureImagesToMap(featureGeometry, featureImagesToAdd);
            this.processingFeatureIds.delete(featureGeometry.featureId);
        });
    }
    getFeatureImagesFromPassedMapByFeatureId(featureId) {
        const featureImages = [];
        if (this.passedFeatureImageMap) {
            Object.values(this.passedFeatureImageMap).forEach((featureImage) => {
                if (featureImage.featureId === featureId)
                    featureImages.push(featureImage);
            });
        }
        return featureImages;
    }
    addFeatureImagesToMap(featureGeometry, featureImagesToAdd) {
        const imageUrls = featureImagesToAdd.map((featureImage) => featureImage.imageUrl);
        if (imageUrls.length === 0)
            return;
        const currentTheme = this.map.getStyle().style;
        const markerDiv = createMarkerDivElement(imageUrls, currentTheme);
        const polygonGeometry = featureGeometry.geometry;
        const labelCoordinate = polylabel(polygonGeometry.coordinates, polylabelPrecision);
        const htmlMarker = new HtmlMarker({
            htmlContent: markerDiv,
            position: labelCoordinate,
            visible: this.visibleFeatureIds.has(featureGeometry.featureId)
        });
        this.map.markers.add(htmlMarker);
        this.featureMarkerMap[featureGeometry.featureId] = htmlMarker;
    }
}
