export var TraversalType;
(function (TraversalType) {
    TraversalType["Elevator"] = "Elevator";
    TraversalType["Stairwell"] = "Stairwell";
})(TraversalType || (TraversalType = {}));
export var RouteDataProperty;
(function (RouteDataProperty) {
    RouteDataProperty["FloorId"] = "floorId";
    RouteDataProperty["FloorName"] = "floorName";
    RouteDataProperty["FloorTraversalType"] = "floorTraversalType";
    RouteDataProperty["DestinationFloorId"] = "destinationFloorId";
    RouteDataProperty["DestinationFloorName"] = "destinationFloorName";
    RouteDataProperty["IsGoingUp"] = "isGoingUp";
    RouteDataProperty["IsStartPin"] = "isStartPin";
})(RouteDataProperty || (RouteDataProperty = {}));
