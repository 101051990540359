import { textColorsDark, textColorsLight } from "../MapLayer.Constants";
import { BuildingOverlayDataProperty } from "./BuildingOverlayLayer.Types";
import { MapTheme } from "../../Components/AzureMapComponent.Types";
import { OutdoorMapIconLabels } from "../../Assets";
export const buildingOverlayLayerDataSourceOptions = {
    cluster: true,
    clusterRadius: 80,
    clusterProperties: {
        [BuildingOverlayDataProperty.CafeCount]: [
            "+",
            ["case", ["==", ["get", BuildingOverlayDataProperty.HasCafe], true], 1, 0]
        ]
    }
};
export const buildingOverlayMarkerLayerFilter = ["!", ["has", "point_count"]];
export const buildingOverlayClusterLayerFilter = ["has", "point_count"];
export const buildingOverlayClusterClickZoomDuration = 200;
export const buildingOverlayIconSize = 1;
export const buildingOverlayFontRegular = ["SegoeUi-Regular"];
export const buildingOverlayFontBold = ["SegoeUi-Bold"];
export const buildingOverlayTextColor = "white";
export const buildingOverlayMarkerLayerIconOptions = {
    image: [
        "case",
        ["get", BuildingOverlayDataProperty.HasCafe],
        OutdoorMapIconLabels.OutdoorMapBuildingPlusCafeIcon,
        OutdoorMapIconLabels.OutdoorMapBuildingIcon
    ],
    allowOverlap: true,
    ignorePlacement: true,
    size: buildingOverlayIconSize
};
export const buildingOverlayMarkerLayerTextOptions = {
    textField: ["get", BuildingOverlayDataProperty.BuildingName],
    font: buildingOverlayFontRegular,
    size: 11,
    color: textColorsDark.color,
    offset: [0, 1.2],
    haloColor: textColorsDark.haloColor,
    haloBlur: 0.5,
    haloWidth: 1,
    allowOverlap: true
};
export const buildingOverlayClusterMarkerLayerIconOptions = {
    image: OutdoorMapIconLabels.OutdoorMapClusterBuildingsIcon,
    allowOverlap: true
};
export const buildingOverlayClusterMarkerLayerTextOptions = {
    textField: ["get", "point_count_abbreviated"],
    offset: [0, -1.4],
    font: buildingOverlayFontBold,
    color: buildingOverlayTextColor,
    size: 14,
    allowOverlap: false
};
export const buildingOverlayClusterLabelLayerIconOptions = {
    image: "none",
    allowOverlap: true
};
export const buildingOverlayClusterLabelLayerTextOptions = {
    textField: [
        "concat",
        ["to-string", ["get", "point_count_abbreviated"]],
        " Microsoft \nbuildings",
        [
            "case",
            ["==", ["get", BuildingOverlayDataProperty.CafeCount], 1],
            ["concat", " & ", ["to-string", ["get", BuildingOverlayDataProperty.CafeCount]], "\ncafe"],
            [">", ["get", BuildingOverlayDataProperty.CafeCount], 1],
            ["concat", " & ", ["to-string", ["get", BuildingOverlayDataProperty.CafeCount]], "\ncafes"],
            ""
        ]
    ],
    offset: [
        "case",
        [">", ["get", BuildingOverlayDataProperty.CafeCount], 0],
        ["literal", [0, 1.6]],
        ["literal", [0, 1.2]]
    ],
    font: buildingOverlayFontRegular,
    size: 10,
    color: textColorsDark.color,
    haloColor: textColorsDark.haloColor,
    haloBlur: 0.5,
    haloWidth: 1,
    allowOverlap: false
};
export function getBuildingOverlayClusterLabelLayerTextOptions(theme) {
    return Object.assign(Object.assign({}, buildingOverlayClusterLabelLayerTextOptions), { color: theme === MapTheme.Dark ? textColorsDark.color : textColorsLight.color, haloColor: theme === MapTheme.Dark ? textColorsDark.haloColor : textColorsLight.haloColor });
}
export function getBuildingOverlayMarkerLayerTextOptions(theme) {
    return Object.assign(Object.assign({}, buildingOverlayMarkerLayerTextOptions), { color: theme === MapTheme.Dark ? textColorsDark.color : textColorsLight.color, haloColor: theme === MapTheme.Dark ? textColorsDark.haloColor : textColorsLight.haloColor });
}
