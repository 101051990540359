import {
    IFeatureImageProps,
    IFeatureImagesRetrieve,
    IKioskData,
    IUserLocationMarkerPositionData,
    LocationMode,
    useAzureMapsContext
} from "@smartbuilding/azure-maps-react";
import { ImageSize, PersonBlobImageMap } from "../../../redux/Types";
import {
    clearDetailsPanelStack,
    modifyDetailsPanelStackPage,
    retrievePeopleImages,
    setBuilding,
    setRoom
} from "../../../redux/Actions";
import {
    getBuildingId, // TODO: remove this line after testing
    getCategorySpaceIds,
    getDeviceConfigData,
    getIsPeopleFeatureDisabled,
    getMapLayers,
    getMenuPanelCategory,
    getPeopleInBuilding,
    getPersonImageMap,
    getSelectedSpace,
    getSpacePersonIdMap
} from "../../../redux/Selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { DeferredPromise } from "@smartbuilding/utilities";
import { PoiType } from "@smartbuilding/poi-service";

export function useMapLayerControl(): void {
    const { mapLayerControl, isMapReady, isIndoorMapReady } = useAzureMapsContext();
    const selectedSpace = useSelector(getSelectedSpace);
    const menuSpaces = useSelector(getCategorySpaceIds);
    const menuCategory = useSelector(getMenuPanelCategory);
    const mapLayers = useSelector(getMapLayers);
    const peopleFeaturesDisabled = useSelector(getIsPeopleFeatureDisabled);
    const peopleInBuilding = useSelector(getPeopleInBuilding);
    const personImageMap = useSelector(getPersonImageMap);
    const featurePersonImageRetrieve = useRef<Record<string, IFeatureImagesRetrieve>>({});
    const spacePeopleMap = useSelector(getSpacePersonIdMap);
    const deviceConfigData = useSelector(getDeviceConfigData);
    const currentBuildingId = useSelector(getBuildingId); // TODO: remove this line after testing

    const dispatch = useDispatch();

    const getPeopleImagesInFeature = (
        peopleIds: string[],
        personImageMap: PersonBlobImageMap,
        featureId: string
    ): IFeatureImageProps[] => {
        const peopleImages: IFeatureImageProps[] = [];
        peopleIds.forEach((personId) => {
            const imageUrl = personImageMap[personId];
            if (imageUrl) {
                peopleImages.push({
                    featureId,
                    imageUrl
                });
            }
        });
        return peopleImages;
    };

    useEffect(() => {
        if (!isIndoorMapReady) return;

        mapLayerControl?.setupSpacePinsLayer(
            (spaceId) => {
                dispatch(setRoom(spaceId));
            },
            () => {
                dispatch(modifyDetailsPanelStackPage());
            },
            () => {
                dispatch(clearDetailsPanelStack());
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady]);

    useEffect(() => {
        if (!isIndoorMapReady) return;
        if (menuCategory !== "root") {
            mapLayerControl?.highlightSelectedSpace(selectedSpace?.id ?? "");
        } else {
            if (!selectedSpace) {
                mapLayerControl?.renderSelectedSpace(undefined);
            } else {
                mapLayerControl?.renderSelectedSpace(selectedSpace.id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, selectedSpace]);

    useEffect(() => {
        if (!isIndoorMapReady) return;

        if (menuCategory === "root") {
            mapLayerControl?.renderSpacePins([]);
            return;
        }
        mapLayerControl?.renderSpacePins(menuSpaces);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, menuSpaces, menuCategory]);

    useEffect(() => {
        if (!isIndoorMapReady) return;

        let renderedPOI = undefined;
        for (const key in mapLayers.poi) {
            // Kiosk Location should not be considered a POI
            if (mapLayers.poi[key as PoiType] && (key as PoiType) !== PoiType.KioskLocation) {
                renderedPOI = key;
                break;
            }
        }
        mapLayerControl?.renderSelectedPOIType(renderedPOI);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, mapLayers.poi]);

    useEffect(() => {
        if (!isIndoorMapReady || !deviceConfigData.floorId || !deviceConfigData.location) return;

        mapLayerControl?.setKioskData({
            kioskFloorId: deviceConfigData.floorId,
            kioskLng: deviceConfigData.location.longitude,
            kioskLat: deviceConfigData.location.latitude,
            kioskBearing: deviceConfigData.bearing
        } as IKioskData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, deviceConfigData]);

    useEffect(() => {
        if (!isIndoorMapReady) return;
        mapLayerControl?.setPeopleOverlayFeatureDisabled(peopleFeaturesDisabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, peopleFeaturesDisabled]);

    useEffect(() => {
        if (
            !isIndoorMapReady ||
            !mapLayers.people ||
            peopleFeaturesDisabled ||
            Object.keys(spacePeopleMap).length === 0
        )
            return;

        const getFeatureImagesCallback = (featureId: string): Promise<IFeatureImageProps[]> => {
            const peopleIds: string[] = [];
            peopleInBuilding.forEach((person) => {
                if (person.spaceId === featureId) peopleIds.push(person.dtId); // TODO: changer to person.featureId once data is migrated
            });
            if (peopleIds.length === 0) return Promise.resolve([]);

            const peopleImages = getPeopleImagesInFeature(peopleIds, personImageMap[ImageSize.Small], featureId);
            if (peopleImages && peopleImages.length > 0) return Promise.resolve(peopleImages);

            const existingRetrieve = featurePersonImageRetrieve.current[featureId];
            if (existingRetrieve) return existingRetrieve.deferredPromise.promise;

            const deferredPromise = new DeferredPromise<IFeatureImageProps[]>();
            featurePersonImageRetrieve.current[featureId] = {
                deferredPromise,
                peopleIds
            };

            dispatch(retrievePeopleImages(peopleIds, ImageSize.Small));
            return deferredPromise.promise;
        };

        mapLayerControl?.setupPeopleOverlayLayer(getFeatureImagesCallback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndoorMapReady, peopleFeaturesDisabled, mapLayers.people, spacePeopleMap]);

    useEffect(() => {
        if (featurePersonImageRetrieve.current) {
            Object.keys(featurePersonImageRetrieve.current).forEach((featureId) => {
                const retrieve = featurePersonImageRetrieve.current[featureId];
                const peopleImages = getPeopleImagesInFeature(
                    retrieve.peopleIds,
                    personImageMap[ImageSize.Small],
                    featureId
                );
                if (peopleImages && peopleImages.length > 0) {
                    retrieve.deferredPromise.resolve(peopleImages);
                    delete featurePersonImageRetrieve.current[featureId];
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personImageMap[ImageSize.Small]]);

    /**
     * TODO: Below are outdoor map testing code, remove after integrate with Client
     */
    useEffect(() => {
        if (!isMapReady) return;

        mapLayerControl?.setupBuildingOverlayLayer((buildingId: string) => {
            dispatch(setBuilding(buildingId));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapReady]);

    useEffect(() => {
        if (!isMapReady) return;

        const mockPositionData: IUserLocationMarkerPositionData = {
            lng: -122.12244670532455,
            lat: 47.64373896331685,
            locationMode: LocationMode.GPSLocation
        };

        const mockIndoorPositionData: IUserLocationMarkerPositionData = {
            lng: -122.12244670532455,
            lat: 47.64373896331685,
            locationMode: LocationMode.IndoorLocation,
            bearing: 90,
            floorId: "d10d58f9-d7fc-46e9-8940-e7b17e4457a0" // Floor ID for building 31, second floor
        };

        if (currentBuildingId === "") {
            mapLayerControl?.setUserLocationMarkerData(mockPositionData);
        } else {
            mapLayerControl?.setUserLocationMarkerData(mockIndoorPositionData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapReady, currentBuildingId]);
}
