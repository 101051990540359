import { SpaceSubTypes } from "@smartbuilding/adt-v2-types";
var AdtConverters = /** @class */ (function () {
    function AdtConverters() {
    }
    AdtConverters.formatIntoSmartSpace = function (space) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
        var spaceType = this.extractTypeFromModel(space.$metadata.$model);
        var data = {
            dtId: (_a = space.$dtId) !== null && _a !== void 0 ? _a : "",
            name: (_b = space.name) !== null && _b !== void 0 ? _b : "",
            type: spaceType,
            buildingId: space.buildingId
                ? space.buildingId
                : spaceType === SpaceSubTypes.Building
                    ? space.$dtId
                    : undefined,
            floorId: space.floorId ? space.floorId : spaceType === SpaceSubTypes.Floor ? space.$dtId : undefined,
            squareFootArea: space.squareFootArea,
            status: space.status,
            regionId: space.regionId,
            roomKey: space.roomKey,
            seatCount: space.seatCount,
            friendlyName: space.friendlyName,
            roomCapacity: space.roomCapacity,
            capabilities: space.capabilities,
            amenities: space.amenities,
            calendar: (_c = space.hasCalendar) === null || _c === void 0 ? void 0 : _c.relationships[0].target,
            values: ((_d = space.hasValues) === null || _d === void 0 ? void 0 : _d.relationships) && ((_e = space.hasValues) === null || _e === void 0 ? void 0 : _e.relationships.length) > 0
                ? (_f = space.hasValues) === null || _f === void 0 ? void 0 : _f.relationships.map(function (data) { return data.target; }) : [],
            featureIds: space.featureIds,
            azureMapData: space.hasAzureMapData
                ? [
                    {
                        datasetId: (_g = space.hasAzureMapData) === null || _g === void 0 ? void 0 : _g.relationships[0].target.datasetId,
                        tilesetId: (_h = space.hasAzureMapData) === null || _h === void 0 ? void 0 : _h.relationships[0].target.tilesetId,
                        mapConfigurationId: (_j = space.hasAzureMapData) === null || _j === void 0 ? void 0 : _j.relationships[0].target.mapConfigurationId,
                        $metadata: (_k = space.hasAzureMapData) === null || _k === void 0 ? void 0 : _k.relationships[0].target.$metadata
                    }
                ]
                : undefined,
            children: space.hasChildren ? this.convertFloorToSmartSpace((_l = space === null || space === void 0 ? void 0 : space.hasChildren) === null || _l === void 0 ? void 0 : _l.relationships) : undefined,
            location: space.hasLocation
                ? [
                    {
                        longitude: (_m = space.hasLocation) === null || _m === void 0 ? void 0 : _m.relationships[0].target.longitude,
                        latitude: (_o = space.hasLocation) === null || _o === void 0 ? void 0 : _o.relationships[0].target.latitude,
                        angle: (_p = space.hasLocation) === null || _p === void 0 ? void 0 : _p.relationships[0].target.angle
                    }
                ]
                : undefined,
            people: space.hasPeople ? this.convertPersonToADTUser((_q = space.hasPeople) === null || _q === void 0 ? void 0 : _q.relationships) : undefined,
            parent: space.hasParent
                ? {
                    dtId: (_u = (_t = (_s = (_r = space.hasParent) === null || _r === void 0 ? void 0 : _r.relationships[0]) === null || _s === void 0 ? void 0 : _s.target) === null || _t === void 0 ? void 0 : _t.$dtId) !== null && _u !== void 0 ? _u : "",
                    name: (_y = (_x = (_w = (_v = space.hasParent) === null || _v === void 0 ? void 0 : _v.relationships[0]) === null || _w === void 0 ? void 0 : _w.target) === null || _x === void 0 ? void 0 : _x.name) !== null && _y !== void 0 ? _y : "",
                    type: this.extractTypeFromModel((_1 = (_0 = (_z = space.hasParent) === null || _z === void 0 ? void 0 : _z.relationships[0]) === null || _0 === void 0 ? void 0 : _0.target) === null || _1 === void 0 ? void 0 : _1.$metadata.$model)
                }
                : undefined
        };
        return data;
    };
    AdtConverters.formatIntoSmartUser = function (user) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var data = {
            dtId: (_a = user.$dtId) !== null && _a !== void 0 ? _a : "",
            givenName: (_b = user.givenName) !== null && _b !== void 0 ? _b : "",
            surName: (_c = user.surName) !== null && _c !== void 0 ? _c : "",
            type: this.extractTypeFromModel(user.$metadata.$model),
            buildingId: user.buildingId,
            floorId: user.floorId,
            regionId: user.regionId,
            space: user.isInSpace && user.isInSpace.relationships.length > 0
                ? this.convertSpaceToSmartSpace(user.isInSpace)
                : undefined,
            properties: {
                businessPhone: user.businessPhone,
                companyName: user.companyName,
                department: user.department,
                displayName: (_d = user.displayName) !== null && _d !== void 0 ? _d : "",
                jobTitle: (_e = user.jobTitle) !== null && _e !== void 0 ? _e : "",
                officeLocation: (_f = user.officeLocation) !== null && _f !== void 0 ? _f : "",
                userPrincipalName: (_g = user.userPrincipalName) !== null && _g !== void 0 ? _g : "",
                mailAddress: user.mailAddress,
                givenName: user.givenName,
                surName: user.surName,
                dtId: (_h = user.$dtId) !== null && _h !== void 0 ? _h : ""
            }
        };
        return data;
    };
    AdtConverters.extractTypeFromModel = function (model) {
        if (!model || model.length === 0) {
            return "";
        }
        var modelArray = model === null || model === void 0 ? void 0 : model.split(";");
        var arrayWithMetadata = modelArray && modelArray[0].split(":").pop();
        return arrayWithMetadata !== null && arrayWithMetadata !== void 0 ? arrayWithMetadata : "";
    };
    AdtConverters.convertPersonToADTUser = function (people) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var retrievedPeople = [];
        for (var _i = 0, people_1 = people; _i < people_1.length; _i++) {
            var person = people_1[_i];
            retrievedPeople.push({
                dtId: (_a = person.target.$dtId) !== null && _a !== void 0 ? _a : "",
                $metadata: person.target.$metadata,
                givenName: (_b = person.target.givenName) !== null && _b !== void 0 ? _b : "",
                surName: (_c = person.target.surName) !== null && _c !== void 0 ? _c : "",
                buildingId: (_d = person.target.buildingId) !== null && _d !== void 0 ? _d : "",
                floorId: (_e = person.target.floorId) !== null && _e !== void 0 ? _e : "",
                regionId: (_f = person.target.regionId) !== null && _f !== void 0 ? _f : "",
                mailAddress: (_g = person.target.mailAddress) !== null && _g !== void 0 ? _g : "",
                userPrincipalName: (_h = person.target.userPrincipalName) !== null && _h !== void 0 ? _h : "",
                displayName: (_j = person.target.displayName) !== null && _j !== void 0 ? _j : "",
                jobTitle: (_k = person.target.jobTitle) !== null && _k !== void 0 ? _k : "",
                officeLocation: (_l = person.target.officeLocation) !== null && _l !== void 0 ? _l : ""
            });
        }
        return retrievedPeople;
    };
    AdtConverters.convertFloorToSmartSpace = function (floors) {
        var retrievedSpaces = [];
        for (var _i = 0, floors_1 = floors; _i < floors_1.length; _i++) {
            var floor = floors_1[_i];
            retrievedSpaces.push({
                dtId: floor.target.$dtId,
                logicalOrder: floor.target.logicalOrder,
                name: floor.target.name,
                roomKey: floor.target.roomKey,
                type: this.extractTypeFromModel(floor.target.$metadata.$model)
            });
        }
        return retrievedSpaces;
    };
    AdtConverters.convertSpaceToSmartSpace = function (space) {
        var _a, _b;
        var spaceFromRelationship = space.relationships[0].target;
        var smartSpace = {
            name: (_a = spaceFromRelationship.name) !== null && _a !== void 0 ? _a : "",
            dtId: (_b = spaceFromRelationship.$dtId) !== null && _b !== void 0 ? _b : "",
            type: this.extractTypeFromModel(spaceFromRelationship.$metadata.$model),
            buildingId: spaceFromRelationship.buildingId,
            floorId: spaceFromRelationship.floorId,
            featureId: spaceFromRelationship.featureId,
            roomKey: spaceFromRelationship.roomKey
        };
        return smartSpace;
    };
    return AdtConverters;
}());
export { AdtConverters };
