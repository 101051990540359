import { AzureMap, DeviceType, MapTheme, useAzureMapsContext } from "@smartbuilding/azure-maps-react";
import { FloorSelector, ZoomControl } from "@smartbuilding/ui-components-map-controls";
import { IMapControlBaseProps, IMapControlStyleProps, IMapControlStyles } from "./MapControl.Types";
import React, { useEffect, useState } from "react";
import { clearPoiLayer, setFloor, spaceClickedAction } from "../../../redux/Actions";
import {
    getBuildingAzureMapData,
    getBuildingCategories,
    getBuildingId,
    getDeepLinkFloorId,
    getFloorId,
    getFloors,
    getRoomMap,
    getSelectedCategory
} from "../../../redux/Selectors";
import { useDispatch, useSelector } from "react-redux";
import { HttpServiceInterceptor } from "../../../auth/HttpServiceInterceptor";
import { IRoomInfo } from "../../../redux/Types";
import ReactDOM from "react-dom";
import { classNamesFunction } from "@fluentui/react";
import { combineCategorySpaceId } from "../../DetailsPanel/ListViewCard/useListViewCardUtilities";
import { electronService } from "@smartbuilding/electron-service";
import { serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { useDebounce } from "../../../hooks/useDebounce";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";
import { useMapData } from "./useMapData";
import { useMapLayerControl } from "./useMapLayerControl";

const getClassNames = classNamesFunction<IMapControlStyleProps, IMapControlStyles>();
const getNumberOfRoomsPerFloor = (spaceList: IRoomInfo[]): Map<string, number> => {
    const floorToRoomCountMap = new Map();

    for (const space of spaceList) {
        const spaceFloorId = space.cardAttributes.floorId;
        const currentCountForFloor = floorToRoomCountMap.get(spaceFloorId) || 0;
        floorToRoomCountMap.set(spaceFloorId, currentCountForFloor + 1);
    }

    return floorToRoomCountMap;
};

export function NewMapControlBase(props: IMapControlBaseProps): JSX.Element {
    const requestInterceptors =
        useInjection<HttpServiceInterceptor>(serviceIdentifiers.httpServiceInterceptor)
            .getHttpServiceInterceptors()
            .request?.map((interceptor) => interceptor.onFulfilled) ?? [];

    const { configurationService, azureMapsTokenService, theme, smartBuildingApiHttpProvider } = props;
    const clientTheme = theme.isInverted ? MapTheme.Light : MapTheme.Dark;
    const [testTheme, setTestTheme] = useState<MapTheme>(clientTheme);
    const [clientId, setClientId] = useState<string | undefined>(undefined);
    const [numberOfRoomsPerFloor, setNumberOfRoomsPerFloor] = useState<Map<string, number> | undefined>(undefined);
    const azureMapsContext = useAzureMapsContext();
    const dispatch = useDispatch();

    const mapData = useSelector(getBuildingAzureMapData);
    const floorId = useSelector(getFloorId);
    const buildingId = useSelector(getBuildingId);
    const floors = useSelector(getFloors);
    const buildingCategories = useSelector(getBuildingCategories);
    const spaceMap = useSelector(getRoomMap);
    const selectedCategory = useSelector(getSelectedCategory);
    const deepLinkFloorId = useSelector(getDeepLinkFloorId);

    const [testPeopleOverlayFeatureDisabled, setTestPeopleOverlayFeatureDisabled] = useState<boolean>(false); // TODO: testing code, remove it after integration
    const [showTestPersonLayer, setShowTestPersonLayer] = useState<boolean>(false); // TODO testing code, remove it after integration

    const classNames = getClassNames(props.styles, {
        theme: theme
    });
    const { indoorMapControl, isIndoorMapReady, isMapDataReady, mapControl, mapLayerControl } = useAzureMapsContext();

    useMapData();
    useMapLayerControl();
    const debouncedSelectedSpace = useDebounce(azureMapsContext.currentSpaceData, 300);

    // initilizing props for the map
    useEffect(() => {
        async function initializeMapProps(): Promise<void> {
            configurationService.getSetting("AzureMapsClientId").then(async (clientId) => {
                setClientId(clientId as string);
            });
        }
        initializeMapProps();
    }, [configurationService, smartBuildingApiHttpProvider]);

    useEffect(() => {
        // TODO: see if we want to do anything with this
    }, [azureMapsContext.currentBuildingId]);

    useEffect(() => {
        if (debouncedSelectedSpace) {
            dispatch(spaceClickedAction({ id: debouncedSelectedSpace.originalId, name: debouncedSelectedSpace.name }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSelectedSpace]);

    useEffect(() => {
        if (azureMapsContext.currentFloorId) {
            dispatch(setFloor(azureMapsContext.currentFloorId));
            dispatch(clearPoiLayer());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [azureMapsContext.currentFloorId]);

    useEffect(() => {
        if (!selectedCategory) {
            setNumberOfRoomsPerFloor(undefined);
        } else if (buildingCategories && buildingCategories[selectedCategory]) {
            const spaceIds = combineCategorySpaceId(buildingCategories, selectedCategory);
            const spaceList = spaceIds.map((id) => spaceMap[id]);
            setNumberOfRoomsPerFloor(getNumberOfRoomsPerFloor(spaceList));
        }
    }, [buildingCategories, selectedCategory, spaceMap]);

    useEffect(() => {
        mapControl?.setTheme(testTheme);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testTheme]);

    return (
        <>
            <div className={classNames.mapControlPanel}>
                {isMapDataReady && (
                    <FloorSelector
                        selectedKey={floorId}
                        floors={floors.map((f) => ({
                            key: f.id,
                            name: f.name,
                            onClick: () => indoorMapControl?.goToFloor(f.id),
                            availability: numberOfRoomsPerFloor?.get(f.id)
                        }))}
                    />
                )}
                {isIndoorMapReady && (
                    <ZoomControl
                        onZoomInClick={() => mapControl?.zoomIn()}
                        onZoomOutClick={() => mapControl?.zoomOut()}
                    />
                )}
                <button onClick={() => setTestTheme(testTheme === MapTheme.Dark ? MapTheme.Light : MapTheme.Dark)}>
                    theme
                </button>
                <button
                    onClick={() => {
                        mapLayerControl?.resetToUserLocation();
                    }}
                >
                    Reset To User Location
                </button>
                <button
                    onClick={() => {
                        if (showTestPersonLayer) {
                            mapLayerControl?.setPersonLayerData(undefined);
                            setShowTestPersonLayer(false);
                        } else {
                            mapLayerControl?.setPersonLayerData({
                                displayName: "Joey Xiong",
                                featureId: "UNIT413",
                                spaceId: "186ef262-533e-48c8-b774-531968e7ec16",
                                floorId: "ef9f02c1-09c2-40bf-b031-3f9d9e93a039",
                                cameraOffset: [0, 0]
                            }); // 31/3U00
                            setShowTestPersonLayer(true);
                        }
                    }}
                >
                    Toggle PersonLayer
                </button>
                <button
                    onClick={() => {
                        mapLayerControl?.setPeopleOverlayFeatureDisabled(!testPeopleOverlayFeatureDisabled);
                        setTestPeopleOverlayFeatureDisabled(!testPeopleOverlayFeatureDisabled);
                    }}
                >
                    Toggle People Overlay Feature Disabled
                </button>
            </div>
            {ReactDOM.createPortal(
                // TODO: move mapData undefined checks to outside of Azure Map, once V2 is migrated
                <AzureMap
                    mapTokenCallback={(): Promise<string> => azureMapsTokenService.getAzureMapsToken()}
                    requestInterceptors={requestInterceptors}
                    clientId={clientId}
                    theme={clientTheme}
                    mapData={mapData}
                    // buildingId={buildingId}
                    buildingId={buildingId.length > 0 ? buildingId : undefined} // TODO: testing code
                    initialFloorId={deepLinkFloorId ?? floorId}
                    config={electronService.isElectron() ? DeviceType.Kiosk : DeviceType.Web}
                />,
                document.getElementById("react-app") as Element
            )}
        </>
    );
}
