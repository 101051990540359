export const personMarkerBorder = {
    dark: "3px solid #000",
    light: "3px solid #FFF"
};
export const extraPeopleBackground = {
    dark: "#FFFFFF",
    light: "#333333"
};
export const extraPeopleColor = {
    dark: "#616161",
    light: "#ADADAD"
};
export const maxFeatureImageCount = 3;
export const baseOffset = 13;
export const peopleOverlayMinZoom = 20.15;
export const peopleOverlayMaxZoom = 24;
