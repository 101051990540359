import { IAction, IBaseAction } from "./IAction";
import { IMapRouteData } from "@smartbuilding/azure-maps-react";
import { IMobileHandOffData } from "@smartbuilding/myhub-api-service";
import { IPathRequest } from "../Types";

export enum PathFindingRetrieveActions {
    RETRIEVING_PATH = "RETRIEVING_PATH", // Dispatched by PathFinding Saga
    PATH_RETRIEVED = "PATH_RETRIEVED", // Dispatched by PathFinding Saga
    IS_WAY_FINDING_SUPPORTED = "IS_WAY_FINDING_SUPPORTED", // Handled by People Saga
    IS_WAY_FINDING_SUPPORTED_RETRIEVED = "IS_WAY_FINDING_SUPPORTED_RETRIEVED", // Dispatched by People Saga and handled in People Reducer
    DEEP_LINK_DATA_ID_RETRIEVED = "DEEP_LINK_DATA_ID_RETRIEVED"
}

export enum PathFindingSelectionActions {
    SELECT_PATH = "SELECT_PATH", // Handled by Reducer to update store and PathFinding Saga to start the retrieve process
    CLEAR_PATH_SELECTION = "CLEAR_PATH_SELECTION"
}

export type IsWayFindingSupportedAction = IAction<string, PathFindingRetrieveActions.IS_WAY_FINDING_SUPPORTED>;
export type IsWayFindingSupportedRetrievedAction = IAction<
    { floorId: string; isWayFindingSupportedStatus: boolean },
    PathFindingRetrieveActions.IS_WAY_FINDING_SUPPORTED_RETRIEVED
>;

export type RetrievingPathAction = IAction<IPathRequest, PathFindingRetrieveActions.RETRIEVING_PATH>;
export type PathRetrievedAction = IAction<
    { request: IPathRequest; routeData: IMapRouteData | null; qrCodeParams: IMobileHandOffData | undefined },
    PathFindingRetrieveActions.PATH_RETRIEVED
>;
export type SelectPathAction = IAction<IPathRequest, PathFindingSelectionActions.SELECT_PATH>;
export type ClearPathSelection = IBaseAction<PathFindingSelectionActions.CLEAR_PATH_SELECTION>;
export type DeepLinkDataIdRetrieveAction = IAction<
    { qrcodeUrl: string; data: IMobileHandOffData },
    PathFindingRetrieveActions.DEEP_LINK_DATA_ID_RETRIEVED
>;
export type PathFindingAction =
    | RetrievingPathAction
    | PathRetrievedAction
    | SelectPathAction
    | ClearPathSelection
    | IsWayFindingSupportedAction
    | IsWayFindingSupportedRetrievedAction
    | DeepLinkDataIdRetrieveAction;
