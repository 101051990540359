import { indoorLayerMinZoom, textColorsDark } from "../MapLayer.Constants";
import { MapFeatureProperty } from "../../Constants/Identifiers";
export const pinTopIconOptions = {
    image: ["get", MapFeatureProperty.Category],
    allowOverlap: true,
    ignorePlacement: true,
    offset: [0, -14],
    size: 0.5
};
export const pinTailIconOptions = {
    image: "PinTail",
    allowOverlap: true,
    ignorePlacement: true,
    size: 0.5
};
const textFieldExpression = [
    "format",
    ["get", MapFeatureProperty.Name],
    "\n",
    {},
    ["get", "busynessStatus"],
    {
        "text-color": ["get", "busynessStatusColor"]
    }
];
export const textOptions = {
    textField: ["case", ["has", "busynessStatus"], textFieldExpression, ["get", MapFeatureProperty.Name]],
    font: ["SegoeUi-SemiBold"],
    color: textColorsDark.color,
    haloColor: textColorsDark.haloColor,
    haloBlur: 0.5,
    haloWidth: 1,
    offset: ["case", ["has", "busynessStatus"], ["literal", [0, 1.1]], ["literal", [0, 0.5]]],
    size: 11
};
export const zoomThreshold = { min: indoorLayerMinZoom, max: 20.15 };
export var SizeConstants;
(function (SizeConstants) {
    SizeConstants[SizeConstants["DEFAULT"] = 0.5] = "DEFAULT";
    SizeConstants[SizeConstants["SELECTED"] = 0.9] = "SELECTED";
})(SizeConstants || (SizeConstants = {}));
