import { layer } from "azure-maps-control";
import { LayerIds, MapFeatureProperty, MapLayerIds } from "../../Constants/Identifiers";
import { SizeConstants, pinTailIconOptions, pinTopIconOptions, textOptions, zoomThreshold } from "./SpacePinsLayer.Constants";
import { removeMapIconsAndLabels, restoreMapIconsAndLabels } from "../MapLayerUtilities";
import { textColorsDark, textColorsLight } from "../MapLayer.Constants";
import { InternalMapControlContainer } from "../../Controls";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
import { MapTheme } from "../../Components/AzureMapComponent.Types";
import { findSpaceLocation } from "./SpacePinsLayer.Helper";
export class SpacePinsLayer {
    constructor(map, dataSource) {
        this.map = map;
        this.dataSource = dataSource;
        this.clickShouldHighlightPin = true;
        this.isVisible = true;
        this._spaceIdsDisplayed = [];
        this._floorId = "";
        this._selectedSpaceId = null;
        this.onThemeChange = (theme) => {
            this.spacePinsTailLayer.setOptions({
                textOptions: Object.assign(Object.assign({}, textOptions), { color: theme === MapTheme.Dark ? textColorsDark.color : textColorsLight.color, haloColor: theme === MapTheme.Dark ? textColorsDark.haloColor : textColorsLight.haloColor })
            });
        };
        this.onPinClick = (event) => {
            var _a, _b, _c;
            if (this.clickShouldHighlightPin) {
                const shapes = event.shapes;
                if (shapes.length) {
                    const shape = shapes[0];
                    const originalId = shape.getProperties()[MapFeatureProperty.OriginalId];
                    if (this.selectedSpaceId !== originalId) {
                        this.selectedSpaceId = originalId;
                        this.panMapCameraToSpace(originalId);
                        (_a = this.selectPinCallback) === null || _a === void 0 ? void 0 : _a.call(this, originalId);
                    }
                    else {
                        this.selectedSpaceId = null;
                        (_b = this.unselectPinCallback) === null || _b === void 0 ? void 0 : _b.call(this);
                    }
                }
            }
            else {
                this.renderPins([]);
                (_c = this.dismissPinCallback) === null || _c === void 0 ? void 0 : _c.call(this);
            }
        };
        this.onFloorChange = (floorId) => {
            this.floorId = floorId;
        };
        this.mouseoverCursor = () => {
            this.map.getCanvasContainer().style.cursor = "pointer";
        };
        this.mouseoutCursor = () => {
            this.map.getCanvasContainer().style.cursor = "grab";
        };
        this.spacePinsTopLayer = new layer.SymbolLayer(this.dataSource, LayerIds.SpacePinsTopLayer, {
            iconOptions: pinTopIconOptions,
            minZoom: zoomThreshold.min,
            maxZoom: zoomThreshold.max
        });
        this.spacePinsTailLayer = new layer.SymbolLayer(this.dataSource, LayerIds.SpacePinsTailLayer, {
            iconOptions: pinTailIconOptions,
            textOptions: textOptions,
            minZoom: zoomThreshold.min,
            maxZoom: zoomThreshold.max
        });
        this.setLayerFilter();
        this.map.layers.add([this.spacePinsTailLayer, this.spacePinsTopLayer]);
        this.subscribeToEvents();
    }
    get spaceIdsDisplayed() {
        return this._spaceIdsDisplayed;
    }
    set spaceIdsDisplayed(value) {
        this._spaceIdsDisplayed = value;
        this.selectedSpaceId = null;
        this.setLayerFilter();
    }
    get floorId() {
        return this._floorId;
    }
    set floorId(value) {
        this._floorId = value;
        this.setLayerFilter();
    }
    get selectedSpaceId() {
        return this._selectedSpaceId;
    }
    set selectedSpaceId(value) {
        this._selectedSpaceId = value;
        this.setPinImageExpression();
        value && this.panMapCameraToSpace(value);
    }
    show() {
        this.spacePinsTopLayer.setOptions({ visible: true });
        this.spacePinsTailLayer.setOptions({ visible: true });
        this.isVisible = true;
        if (this.spaceIdsDisplayed.length && this.clickShouldHighlightPin) {
            removeMapIconsAndLabels(this.map);
        }
    }
    hide() {
        this.spacePinsTopLayer.setOptions({ visible: false });
        this.spacePinsTailLayer.setOptions({ visible: false });
        this.isVisible = false;
        restoreMapIconsAndLabels(this.map);
    }
    setSelectPinCallback(callback) {
        this.selectPinCallback = callback;
    }
    setUnselectPinCallback(callback) {
        this.unselectPinCallback = callback;
    }
    setDismissPinCallback(callback) {
        this.dismissPinCallback = callback;
    }
    renderPins(spaceIds) {
        this.clickShouldHighlightPin = true;
        this.spaceIdsDisplayed = spaceIds;
        if (spaceIds.length) {
            this.isVisible && removeMapIconsAndLabels(this.map);
            MapEventsEmitter.getInstance().emit("layerActive", MapLayerIds.SpacePinsLayer);
        }
        else {
            restoreMapIconsAndLabels(this.map);
            MapEventsEmitter.getInstance().emit("layerInactive", MapLayerIds.SpacePinsLayer);
        }
    }
    renderSinglePin(spaceId) {
        if (!spaceId) {
            this.spaceIdsDisplayed = [];
            return;
        }
        this.clickShouldHighlightPin = false;
        this.panMapCameraToSpace(spaceId);
        this.spaceIdsDisplayed = [spaceId];
    }
    setFloorId(floorId) {
        this.floorId = floorId;
    }
    overrideMaxZoom(maxZoom = 24) {
        if (maxZoom <= 24 && maxZoom >= zoomThreshold.min) {
            this.spacePinsTopLayer.setOptions({ maxZoom });
            this.spacePinsTailLayer.setOptions({ maxZoom });
        }
    }
    highlightPin(spaceId) {
        if (this.spaceIdsDisplayed.includes(spaceId)) {
            this.selectedSpaceId = spaceId;
        }
    }
    getLayerVisibility() {
        return this.isVisible && this.spaceIdsDisplayed.length > 0 && this.clickShouldHighlightPin;
    }
    subscribeToEvents() {
        this.map.events.add("click", this.spacePinsTopLayer, this.onPinClick);
        this.map.events.add("mouseover", this.spacePinsTopLayer, this.mouseoverCursor);
        this.map.events.add("mouseout", this.spacePinsTopLayer, this.mouseoutCursor);
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorChange);
        MapEventsEmitter.getInstance().on("themeChanged", this.onThemeChange);
    }
    setLayerFilter() {
        const filter = [
            "all",
            ["has", MapFeatureProperty.Category],
            ["==", ["get", MapFeatureProperty.FloorId], this.floorId],
            ["in", ["get", MapFeatureProperty.OriginalId], ["literal", this.spaceIdsDisplayed]]
        ];
        this.spacePinsTopLayer.setOptions({ filter: filter });
        this.spacePinsTailLayer.setOptions({ filter: filter });
    }
    setPinImageExpression() {
        const pinTopIconOptions = this.spacePinsTopLayer.getOptions().iconOptions;
        const pinTailIconOptions = this.spacePinsTailLayer.getOptions().iconOptions;
        const sizeExpression = [
            "case",
            ["==", ["get", MapFeatureProperty.OriginalId], this.selectedSpaceId],
            SizeConstants.SELECTED,
            SizeConstants.DEFAULT
        ];
        this.spacePinsTopLayer.setOptions({ iconOptions: Object.assign(Object.assign({}, pinTopIconOptions), { size: sizeExpression }) });
        this.spacePinsTailLayer.setOptions({ iconOptions: Object.assign(Object.assign({}, pinTailIconOptions), { size: sizeExpression }) });
    }
    panMapCameraToSpace(spaceId) {
        var _a, _b;
        const currentZoom = (_a = this.map.getCamera().zoom) !== null && _a !== void 0 ? _a : 0;
        if (currentZoom > zoomThreshold.max || currentZoom < zoomThreshold.min)
            return;
        const spaceLocation = findSpaceLocation(spaceId, this.dataSource);
        if (spaceLocation && this.isVisible) {
            if (this.floorId !== spaceLocation.floorId) {
                (_b = InternalMapControlContainer.getInstance().indoorMapControl) === null || _b === void 0 ? void 0 : _b.goToFloor(spaceLocation.floorId);
            }
            this.map.setCamera({
                center: spaceLocation.coordinate,
                duration: 200,
                type: "ease"
            });
        }
    }
}
