import { source } from "azure-maps-control";
import { DataSourceIds } from "../Constants/Identifiers";
import { buildingOverlayLayerDataSourceOptions } from "../Layers";
export class DataSourcesManager {
    constructor() {
        this.dataSources = {
            features: new source.DataSource(DataSourceIds.Features),
            levels: new source.DataSource(DataSourceIds.Levels),
            pointsOfInterest: new source.DataSource(DataSourceIds.PointsOfInterest),
            route: new source.DataSource(DataSourceIds.Route),
            routePins: new source.DataSource(DataSourceIds.RoutePins),
            routeClickablePins: new source.DataSource(DataSourceIds.RouteClickablePins),
            buildingOverlay: new source.DataSource(DataSourceIds.BuildingOverlay, buildingOverlayLayerDataSourceOptions)
        };
    }
    initialize(map) {
        this.map = map;
        for (const dataSource of Object.values(this.dataSources)) {
            this.map.sources.add(dataSource);
        }
    }
    setFeaturesData(features) {
        this.dataSources[DataSourceIds.Features].setShapes(features);
    }
    getFeaturesDataSource() {
        return this.dataSources[DataSourceIds.Features];
    }
    setLevelsData(levels) {
        this.dataSources[DataSourceIds.Levels].setShapes(levels);
    }
    getLevelsDataSource() {
        return this.dataSources[DataSourceIds.Levels];
    }
    setPointsOfInterestData(pois) {
        this.dataSources[DataSourceIds.PointsOfInterest].setShapes(pois);
    }
    getPointsOfInterestDataSource() {
        return this.dataSources[DataSourceIds.PointsOfInterest];
    }
    setRouteData(routeData) {
        this.dataSources[DataSourceIds.Route].setShapes(routeData);
    }
    getRouteDataSource() {
        return this.dataSources[DataSourceIds.Route];
    }
    setRoutePinsData(routePinsData) {
        this.dataSources[DataSourceIds.RoutePins].setShapes(routePinsData);
    }
    getRoutePinsDataSource() {
        return this.dataSources[DataSourceIds.RoutePins];
    }
    setRouteClickablePinsData(routeClickablePinsData) {
        this.dataSources[DataSourceIds.RouteClickablePins].setShapes(routeClickablePinsData);
    }
    getRouteClickablePinsDataSource() {
        return this.dataSources[DataSourceIds.RouteClickablePins];
    }
    setBuildingOverlayData(buildingOverlayData) {
        this.dataSources[DataSourceIds.BuildingOverlay].setShapes(buildingOverlayData);
    }
    getBuildingOverlayDataSource() {
        return this.dataSources[DataSourceIds.BuildingOverlay];
    }
    clearIndoorData() {
        for (const dataSourceKey of Object.keys(this.dataSources)) {
            if (dataSourceKey !== DataSourceIds.BuildingOverlay) {
                this.dataSources[dataSourceKey].clear();
            }
        }
    }
}
