import { data } from "azure-maps-control";
import { DeviceType } from "../Components/AzureMapComponent.Types";
function getMapViewPolygon(map, deviceType) {
    const mapRect = map.getCanvasContainer().getBoundingClientRect();
    const width = mapRect.width;
    const height = mapRect.height;
    const widthOffset = width * (deviceType !== DeviceType.Mobile ? 0.3 : 0);
    const heightOffset = height * (deviceType !== DeviceType.Mobile ? 0.1 : 0);
    const widthEdgeOffset = width * (deviceType !== DeviceType.Mobile ? 0.1 : 0);
    const positions = map.pixelsToPositions([
        [widthOffset, heightOffset],
        [width - widthEdgeOffset, heightOffset],
        [width - widthEdgeOffset, height - heightOffset],
        [widthOffset, height - heightOffset]
    ]);
    positions.push(positions[0]);
    return new data.Polygon(positions);
}
export class AzureMapUtilities {
    static getMapViewPolygon(map) {
        return getMapViewPolygon(map, this.deviceType);
    }
}
AzureMapUtilities.deviceType = DeviceType.Web;
