import { DarkThemeOptions, LightThemeOptions, SensorLayerColors, sensorBaseColorExp, sensorPolygonColorExp } from "./SensorOverlayMapLayer.Constants";
import { layer } from "azure-maps-control";
import { LayerIds, MapFeatureProperty } from "../../Constants/Identifiers";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
import { MapTheme } from "../../Components/AzureMapComponent.Types";
import { indoorLayerMinZoom } from "../MapLayer.Constants";
export class SensorOverlayLayer {
    constructor(map, dataSource) {
        this.map = map;
        this.onFirstIndoorMapReady = () => {
            const layerId = this.map.layers.getLayers().find((layer) => layer.getId().includes("indoor_wall_area"));
            layerId &&
                this.map.layers.add([this.sensorBaseLayer, this.sensorPolygonLayer, this.sensorOutlineLayer], layerId);
        };
        this.onFloorChange = (floorId) => {
            this.sensorPolygonLayer.setOptions({ filter: this.getSensorLayerFilter(floorId) });
            this.sensorBaseLayer.setOptions({ filter: this.getSensorLayerFilter(floorId) });
            this.sensorOutlineLayer.setOptions({ filter: this.getSensorLayerFilter(floorId) });
        };
        this.getSensorLayerFilter = (floorId) => {
            return ["all", ["has", "sensorStatusColor"], ["==", ["get", MapFeatureProperty.FloorId], floorId]];
        };
        this.onThemeChange = (theme) => {
            if (theme === MapTheme.Dark) {
                this.sensorPolygonLayer.setOptions({ fillOpacity: DarkThemeOptions.SENSOR_LAYER_OPACITY });
                this.sensorOutlineLayer.setOptions({ strokeWidth: DarkThemeOptions.OUTLINE_LAYER_STROKE_WIDTH });
            }
            else {
                this.sensorPolygonLayer.setOptions({ fillOpacity: LightThemeOptions.SENSOR_LAYER_OPACITY });
                this.sensorOutlineLayer.setOptions({ strokeWidth: LightThemeOptions.OUTLINE_LAYER_STROKE_WIDTH });
            }
            const layerId = this.map.layers.getLayers().find((layer) => layer.getId().includes("indoor_wall_area"));
            layerId &&
                this.map.layers.add([this.sensorBaseLayer, this.sensorPolygonLayer, this.sensorOutlineLayer], layerId);
        };
        this.sensorPolygonLayer = new layer.PolygonLayer(dataSource, LayerIds.SensorPolygonLayer, {
            fillColor: sensorPolygonColorExp,
            fillOpacity: DarkThemeOptions.SENSOR_LAYER_OPACITY,
            minZoom: indoorLayerMinZoom,
            filter: this.getSensorLayerFilter("")
        });
        this.sensorBaseLayer = new layer.PolygonLayer(dataSource, LayerIds.SensorBaseLayer, {
            fillColor: sensorBaseColorExp,
            fillOpacity: 1,
            minZoom: indoorLayerMinZoom,
            filter: this.getSensorLayerFilter("")
        });
        this.sensorOutlineLayer = new layer.LineLayer(dataSource, LayerIds.SensorOutlineLayer, {
            strokeColor: SensorLayerColors.DarkThemeOutline,
            strokeWidth: DarkThemeOptions.OUTLINE_LAYER_STROKE_WIDTH,
            minZoom: indoorLayerMinZoom,
            filter: this.getSensorLayerFilter("")
        });
        this.map.layers.add([this.sensorBaseLayer, this.sensorPolygonLayer, this.sensorOutlineLayer], "base");
        this.subscribeToEvents();
    }
    subscribeToEvents() {
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorChange);
        MapEventsEmitter.getInstance().on("themeChanged", this.onThemeChange);
        MapEventsEmitter.getInstance().once("indoorMapReady", this.onFirstIndoorMapReady);
    }
    hide() {
        this.sensorPolygonLayer.setOptions({ visible: false });
        this.sensorBaseLayer.setOptions({ visible: false });
        this.sensorOutlineLayer.setOptions({ visible: false });
    }
    show() {
        this.sensorPolygonLayer.setOptions({ visible: true });
        this.sensorBaseLayer.setOptions({ visible: true });
        this.sensorOutlineLayer.setOptions({ visible: true });
    }
}
