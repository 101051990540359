export const iconStart = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" fill="white"/>
</svg>`;
export const startMarkerDragIcon = `<svg width="80" height="140" viewBox="0 0 80 140" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.2" cx="40" cy="102" r="5" fill="black"/>
<g filter="url(#filter0_dd)">
<mask id="path-2-inside-1" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.2422 45.2109C59.3715 42.7058 60 39.9263 60 37C60 25.9543 51.0457 17 40 17C28.9543 17 20 25.9543 20 37C20 39.9261 20.6284 42.7054 21.7576 45.2104H21.7376L21.9674 45.6608C22.1023 45.9411 22.2435 46.2178 22.3908 46.4906L40 80.999L57.5738 46.5558C57.7461 46.2397 57.91 45.9184 58.0654 45.5922L58.26 45.2109H58.2422Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.2422 45.2109C59.3715 42.7058 60 39.9263 60 37C60 25.9543 51.0457 17 40 17C28.9543 17 20 25.9543 20 37C20 39.9261 20.6284 42.7054 21.7576 45.2104H21.7376L21.9674 45.6608C22.1023 45.9411 22.2435 46.2178 22.3908 46.4906L40 80.999L57.5738 46.5558C57.7461 46.2397 57.91 45.9184 58.0654 45.5922L58.26 45.2109H58.2422Z" fill="#0078D4"/>
<path d="M58.2422 45.2109L56.4189 44.389L55.1467 47.2109H58.2422V45.2109ZM21.7576 45.2104V47.2104H24.8529L23.5809 44.3885L21.7576 45.2104ZM21.7376 45.2104V43.2104H18.4717L19.9561 46.1195L21.7376 45.2104ZM21.9674 45.6608L23.7696 44.7936L23.7595 44.7726L23.7489 44.7517L21.9674 45.6608ZM22.3908 46.4906L24.1723 45.5816L24.1617 45.5607L24.1506 45.5402L22.3908 46.4906ZM40 80.999L38.2185 81.9081L40.0001 85.3994L41.7815 81.908L40 80.999ZM57.5738 46.5558L55.8176 45.5989L55.8046 45.6227L55.7923 45.6468L57.5738 46.5558ZM58.0654 45.5922L56.2839 44.6833L56.2716 44.7074L56.2599 44.7319L58.0654 45.5922ZM58.26 45.2109L60.0415 46.1199L61.5257 43.2109H58.26V45.2109ZM58 37C58 39.6374 57.4341 42.1371 56.4189 44.389L60.0655 46.0329C61.309 43.2745 62 40.2153 62 37H58ZM40 19C49.9411 19 58 27.0589 58 37H62C62 24.8497 52.1503 15 40 15V19ZM22 37C22 27.0589 30.0589 19 40 19V15C27.8497 15 18 24.8497 18 37H22ZM23.5809 44.3885C22.5659 42.1368 22 39.6372 22 37H18C18 40.215 18.6909 43.2741 19.9343 46.0323L23.5809 44.3885ZM21.7576 43.2104H21.7376V47.2104H21.7576V43.2104ZM19.9561 46.1195L20.1859 46.5699L23.7489 44.7517L23.519 44.3014L19.9561 46.1195ZM24.1506 45.5402C24.018 45.2947 23.8909 45.0458 23.7696 44.7936L20.1652 46.528C20.3136 46.8364 20.4689 47.1408 20.6311 47.441L24.1506 45.5402ZM20.6094 47.3997L38.2185 81.9081L41.7814 80.09L24.1723 45.5816L20.6094 47.3997ZM41.7815 81.908L59.3553 47.4648L55.7923 45.6468L38.2185 80.0901L41.7815 81.908ZM56.2599 44.7319C56.1201 45.0254 55.9726 45.3144 55.8176 45.5989L59.33 47.5128C59.5195 47.165 59.7 46.8114 59.871 46.4525L56.2599 44.7319ZM59.847 46.5012L60.0415 46.1199L56.4785 44.302L56.2839 44.6833L59.847 46.5012ZM58.26 43.2109H58.2422V47.2109H58.26V43.2109Z" fill="white" mask="url(#path-2-inside-1)"/>
</g>
<circle cx="40" cy="37" r="10" fill="white"/>
<path d="M63.1757 32.0243C62.9414 31.79 62.9414 31.4101 63.1757 31.1757C63.41 30.9414 63.79 30.9414 64.0243 31.1757L69.4243 36.5757C69.6586 36.8101 69.6586 37.19 69.4243 37.4243L64.0243 42.8243C63.79 43.0586 63.41 43.0586 63.1757 42.8243C62.9414 42.59 62.9414 42.21 63.1757 41.9757L68.1515 37L63.1757 32.0243Z" fill="#0078D4"/>
<path d="M35.0243 13.8243C34.79 14.0586 34.4101 14.0586 34.1757 13.8243C33.9414 13.59 33.9414 13.21 34.1757 12.9757L39.5757 7.57568C39.8101 7.34144 40.19 7.34144 40.4243 7.57568L45.8243 12.9757C46.0586 13.21 46.0586 13.59 45.8243 13.8243C45.59 14.0586 45.21 14.0586 44.9757 13.8243L40 8.84852L35.0243 13.8243Z" fill="#0078D4"/>
<path d="M16.8243 41.9757C17.0586 42.21 17.0586 42.5899 16.8243 42.8243C16.59 43.0586 16.21 43.0586 15.9757 42.8243L10.5757 37.4243C10.3414 37.1899 10.3414 36.81 10.5757 36.5757L15.9757 31.1757C16.21 30.9414 16.59 30.9414 16.8243 31.1757C17.0586 31.41 17.0586 31.79 16.8243 32.0243L11.8485 37L16.8243 41.9757Z" fill="#0078D4"/>
<defs>
<filter id="filter0_dd" x="12" y="14.6" width="56" height="94.399" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1.2"/>
<feGaussianBlur stdDeviation="1.8"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.108 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="20"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.132 0"/>
<feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
</filter>
</defs>
</svg>`;
