export const indoorLayerMinZoom = 16.5;
export const indoorMapViewableZoom = 19;
export const indoorMapPaddingWeb = {
    top: 50,
    bottom: 50,
    left: 100,
    right: 10
};
export const indoorMapOffsetWeb = [200, 0];
export const indoorMapPaddingMobile = {
    top: 0,
    bottom: 100,
    left: 10,
    right: 10
};
export const indoorMapOffsetMobile = [0, 0];
export const textColorsLight = {
    color: "#000000",
    haloColor: "#FFFFFF"
};
export const textColorsDark = {
    color: "#FFFFFF",
    haloColor: "#000000"
};
export const polylabelPrecision = 0.000001;
