var MapLabelLayerIds;
(function (MapLabelLayerIds) {
    MapLabelLayerIds["IndoorLabels"] = "indoor_unit_point_label";
})(MapLabelLayerIds || (MapLabelLayerIds = {}));
function getMapLabelLayer(map) {
    var _a;
    return (_a = map.layers
        .getLayers()
        .find((layer) => layer.getId().includes(MapLabelLayerIds.IndoorLabels))) === null || _a === void 0 ? void 0 : _a.getId();
}
export function setLayerTextSpacingProperty(map, layerIds) {
    map.events.addOnce("ready", () => {
        layerIds.forEach((layerId) => {
            map._getMap().setLayoutProperty(layerId, "text-letter-spacing", 0.1);
        });
    });
}
export function removeMapIconsAndLabels(map) {
    map.events.addOnce("ready", () => {
        const labelLayer = map.layers.getLayerById("labels_indoor");
        if (!labelLayer)
            return;
        const configurableMap = map._getMap();
        for (const layer of labelLayer.layers) {
            if (layer.layout["icon-image"]) {
                configurableMap.setLayoutProperty(layer.id, "visibility", "none");
            }
        }
        const indoorLabelLayer = getMapLabelLayer(map);
        indoorLabelLayer && configurableMap.setLayoutProperty(indoorLabelLayer, "visibility", "none");
    });
}
export function restoreMapIconsAndLabels(map) {
    map.events.addOnce("ready", () => {
        const labelLayer = map.layers.getLayerById("labels_indoor");
        if (!labelLayer)
            return;
        const configurableMap = map._getMap();
        for (const layer of labelLayer.layers) {
            if (layer.layout["icon-image"]) {
                configurableMap.setLayoutProperty(layer.id, "visibility", "visible");
            }
        }
        const indoorLabelLayer = getMapLabelLayer(map);
        indoorLabelLayer && configurableMap.setLayoutProperty(indoorLabelLayer, "visibility", "visible");
    });
}
