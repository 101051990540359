import { layer } from "azure-maps-control";
import { LayerIds, MapFeatureProperty, MapLayerIds } from "../../Constants/Identifiers";
import { grayColor, poiLayerIconOptions } from "./POILayer.Constants";
import { MapEventsEmitter } from "../../Services/MapEventsEmitter";
import { indoorLayerMinZoom } from "../MapLayer.Constants";
export class POILayer {
    constructor(map, poiDataSource, levelsDataSource) {
        this.map = map;
        this.poiDataSource = poiDataSource;
        this.levelsDataSource = levelsDataSource;
        this._poiTypeDisplayed = "";
        this._floorId = "";
        this.onFloorChange = (floorId) => {
            this.floorId = floorId;
        };
        this.moveGrayFloorOverlayLayer = () => {
            const layerId = this.map.layers.getLayers().find((layer) => layer.getId().includes("indoor_wall_area"));
            layerId && this.map.layers.add(this.poiGrayFloorOverlayLayer, layerId);
        };
        this.poiIconLayer = new layer.SymbolLayer(this.poiDataSource, LayerIds.PoiIconLayer, {
            iconOptions: poiLayerIconOptions,
            minZoom: indoorLayerMinZoom
        });
        this.poiGrayFloorOverlayLayer = new layer.PolygonLayer(this.levelsDataSource, LayerIds.PoiGrayFloorOverlayLayer, {
            fillColor: grayColor,
            fillOpacity: 1,
            minZoom: indoorLayerMinZoom
        });
        this.map.layers.add(this.poiIconLayer);
        this.map.layers.add(this.poiGrayFloorOverlayLayer, "base");
        this.subscribeToEvents();
    }
    get poiTypeDisplayed() {
        return this._poiTypeDisplayed;
    }
    set poiTypeDisplayed(value) {
        this._poiTypeDisplayed = value;
        this.setLayerFilter();
    }
    get floorId() {
        return this._floorId;
    }
    set floorId(value) {
        this._floorId = value;
        this.setLayerFilter();
    }
    show() {
        this.poiIconLayer.setOptions({ visible: true });
    }
    hide() {
        this.poiIconLayer.setOptions({ visible: false });
    }
    renderSelectedPOIType(poiType) {
        this.poiTypeDisplayed = poiType;
        if (poiType.length > 0) {
            MapEventsEmitter.getInstance().emit("layerActive", MapLayerIds.PoiLayer);
        }
        else {
            MapEventsEmitter.getInstance().emit("layerInactive", MapLayerIds.PoiLayer);
        }
    }
    subscribeToEvents() {
        MapEventsEmitter.getInstance().on("floorIdChanged", this.onFloorChange);
        MapEventsEmitter.getInstance().once("indoorMapReady", this.moveGrayFloorOverlayLayer);
        MapEventsEmitter.getInstance().on("themeChanged", this.moveGrayFloorOverlayLayer);
    }
    setLayerFilter() {
        const filter = [
            "all",
            ["==", ["get", MapFeatureProperty.FloorId], this.floorId],
            ["==", ["get", MapFeatureProperty.PoiType], this.poiTypeDisplayed]
        ];
        const poiGrayFloorOverlayLayerFilter = [
            "all",
            ["==", ["get", MapFeatureProperty.OriginalId], this.floorId],
            ["to-boolean", this.poiTypeDisplayed.length > 0]
        ];
        this.poiIconLayer.setOptions({ filter: filter });
        this.poiGrayFloorOverlayLayer.setOptions({ filter: poiGrayFloorOverlayLayerFilter });
    }
}
