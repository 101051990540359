var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addImageTemplate } from "azure-maps-control";
import { RouteIconLabels, SpacePinIconLabels, outdoorMapIconAssets, poiIconAssets, spacePinAssets, spacePinIcons } from "../Assets";
import { IconSvgCreator } from "./IconSvgCreator";
export function loadSpaceTypeIcons(map) {
    const iconSvgCreator = new IconSvgCreator();
    addImageTemplate(SpacePinIconLabels.PinTail, spacePinIcons.PinTail, true);
    map.imageSprite.createFromTemplate(SpacePinIconLabels.PinTail, SpacePinIconLabels.PinTail, undefined, undefined, 2);
    Object.keys(spacePinAssets).forEach((key) => __awaiter(this, void 0, void 0, function* () {
        const icon = yield iconSvgCreator.createSpacePinSvg(key, false);
        addImageTemplate(key, icon.svg, true);
        map.imageSprite.createFromTemplate(key, key, undefined, undefined, 2);
    }));
}
export function loadPOIIcons(map) {
    Object.keys(poiIconAssets).forEach((key) => __awaiter(this, void 0, void 0, function* () {
        map.imageSprite.add(key, poiIconAssets[key]);
    }));
}
export function loadRoutePinIcons(map) {
    const iconSvgCreator = new IconSvgCreator();
    Object.entries(RouteIconLabels).forEach(([key, value]) => __awaiter(this, void 0, void 0, function* () {
        const icon = yield iconSvgCreator.createRouteMarkerSvg(value);
        map.imageSprite.add(key, icon.svg);
    }));
}
export function loadOutdoorMapIcons(map) {
    Object.keys(outdoorMapIconAssets).forEach((key) => __awaiter(this, void 0, void 0, function* () {
        map.imageSprite.add(key, outdoorMapIconAssets[key]);
    }));
}
