import { baseOffset, extraPeopleBackground, extraPeopleColor, maxFeatureImageCount, personMarkerBorder } from "./PeopleOverlayLayer.Constants";
import { MapTheme } from "../../Components/AzureMapComponent.Types";
export function createMarkerDivElement(imageUrls, theme) {
    const markerDiv = document.createElement("div");
    markerDiv.setAttribute("class", "person-marker");
    imageUrls.splice(0, maxFeatureImageCount).forEach((imageUrl, index) => {
        const offset = baseOffset * index;
        const imgElement = document.createElement("img");
        imgElement.src = imageUrl;
        imgElement.setAttribute("class", "person-marker-image");
        imgElement.setAttribute("alt", "Person Image");
        imgElement.style.transform = `translateX(-${offset}px)`;
        imgElement.style.border = (theme === null || theme === void 0 ? void 0 : theme.includes(MapTheme.Dark)) ? personMarkerBorder.dark : personMarkerBorder.light;
        markerDiv.appendChild(imgElement);
    });
    if (imageUrls.length > 0) {
        const extraPplOffset = baseOffset * maxFeatureImageCount;
        const extraPplDiv = document.createElement("div");
        const extraPplText = document.createTextNode(`+${imageUrls.length}`);
        extraPplDiv.appendChild(extraPplText);
        extraPplDiv.setAttribute("class", `person-marker-extra-ppl-div`);
        extraPplDiv.style.transform = `translate(-${extraPplOffset}px, 3px)`;
        extraPplDiv.style.backgroundColor = (theme === null || theme === void 0 ? void 0 : theme.includes(MapTheme.Dark)) ? extraPeopleBackground.dark
            : extraPeopleBackground.light;
        extraPplDiv.style.color = (theme === null || theme === void 0 ? void 0 : theme.includes(MapTheme.Dark)) ? extraPeopleColor.dark : extraPeopleColor.light;
        extraPplDiv.style.outline = (theme === null || theme === void 0 ? void 0 : theme.includes(MapTheme.Dark)) ? personMarkerBorder.dark : personMarkerBorder.light;
        markerDiv.appendChild(extraPplDiv);
    }
    return markerDiv;
}
