import { indoorMapOffsetMobile, indoorMapOffsetWeb, indoorMapPaddingMobile, indoorMapPaddingWeb, indoorMapViewableZoom } from "../Layers/MapLayer.Constants";
import { DeviceType } from "../Components/AzureMapComponent.Types";
export class InternalMapControlContainer {
    constructor() { }
    static getInstance() {
        if (!InternalMapControlContainer.instance) {
            InternalMapControlContainer.instance = new InternalMapControlContainer();
        }
        return InternalMapControlContainer.instance;
    }
    static setIndoorMapBounds(bounds, deviceType) {
        if (!this.map)
            return;
        this.map.setCamera({
            bounds: bounds,
            padding: deviceType === DeviceType.Mobile ? indoorMapPaddingMobile : indoorMapPaddingWeb,
            offset: deviceType === DeviceType.Mobile ? indoorMapOffsetMobile : indoorMapOffsetWeb
        });
        const currentZoom = this.map.getCamera().zoom;
        if (currentZoom && currentZoom < indoorMapViewableZoom) {
            this.map.setCamera({
                zoom: indoorMapViewableZoom
            });
        }
    }
}
