export class NullLayer {
    show() { }
    hide() { }
}
export class NullKioskLayer {
    show() { }
    hide() { }
    setKioskData(kioskData) { }
}
export class NullBuildingOverlayLayer {
    show() { }
    hide() { }
    setOnClickBuildingCallback(callback) { }
}
export class NullUserLocationMarkerLayer {
    show() { }
    hide() { }
    updateLocation(positionData) { }
    resetToUserLocation() { }
}
export class NullPersonLayer {
    show() { }
    hide() { }
    setPersonLayerData(personLayerData) { }
}
