import { AzureMapUtilities } from "./AzureMapUtilities";
import { booleanIntersects } from "@turf/boolean-intersects";
export function getDataSourceFeaturesInView(map, dataSource, floorId, category = []) {
    const mapViewPolygon = AzureMapUtilities.getMapViewPolygon(map);
    const features = dataSource.toJson().features;
    const filteredFeatures = features.filter((feature) => {
        var _a, _b;
        if (((_a = feature.properties) === null || _a === void 0 ? void 0 : _a.floorId) !== floorId)
            return false;
        if (category.length && !category.includes((_b = feature.properties) === null || _b === void 0 ? void 0 : _b.category))
            return false;
        const featurePolygon = feature.geometry;
        return booleanIntersects(featurePolygon, mapViewPolygon);
    });
    return filteredFeatures;
}
