export const outdoorMarkerBase = `<svg width="70" height="77" viewBox="0 0 70 77" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.9285 46.2441C48.0387 43.4957 50 39.4769 50 35C50 26.7157 43.2843 20 35 20C26.7157 20 20 26.7157 20 35C20 39.4769 21.9613 43.4957 25.0715 46.2441L35 57L44.9285 46.2441Z" fill="#0078D7"/>
</g>
<defs>
<filter id="filter0_d" x="16" y="14" width="38" height="45" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>`;
