export class MapLayerControl {
    constructor(mapLayersManager) {
        this.mapLayersManager = mapLayersManager;
    }
    renderSpacePins(spaceIds) {
        this.mapLayersManager.spacePinsLayer.renderPins(spaceIds);
    }
    renderSelectedSpace(spaceId) {
        this.mapLayersManager.spacePinsLayer.renderSinglePin(spaceId);
    }
    highlightSelectedSpace(spaceId) {
        this.mapLayersManager.spacePinsLayer.highlightPin(spaceId);
    }
    setupSpacePinsLayer(selectPinCallback, unselectPinCallback, dismissPinCallback, maxZoom) {
        this.mapLayersManager.spacePinsLayer.setSelectPinCallback(selectPinCallback);
        this.mapLayersManager.spacePinsLayer.setUnselectPinCallback(unselectPinCallback);
        this.mapLayersManager.spacePinsLayer.setDismissPinCallback(dismissPinCallback);
        maxZoom && this.mapLayersManager.spacePinsLayer.overrideMaxZoom(maxZoom);
    }
    renderSelectedPOIType(poiType) {
        this.mapLayersManager.poiLayer.renderSelectedPOIType(poiType !== null && poiType !== void 0 ? poiType : "");
    }
    setupPeopleOverlayLayer(getFeatureImagesCallback, passedFeatureImageMap) {
        this.mapLayersManager.peopleOverlayLayer.setFeatureImagesCallback(getFeatureImagesCallback, passedFeatureImageMap);
    }
    setPeopleOverlayFeatureDisabled(peopleOverlayFeatureDisabled) {
        this.mapLayersManager.peopleOverlayLayer.setPeopleOverlayFeatureDisabled(peopleOverlayFeatureDisabled);
    }
    setKioskData(kioskData) {
        this.mapLayersManager.kioskBlueDotLayer.setKioskData(kioskData);
    }
    setupBuildingOverlayLayer(callback) {
        this.mapLayersManager.buildingOverlayLayer.setOnClickBuildingCallback(callback);
    }
    setUserLocationMarkerData(positionData) {
        this.mapLayersManager.userLocationMarkerLayer.updateLocation(positionData);
    }
    resetToUserLocation() {
        this.mapLayersManager.userLocationMarkerLayer.resetToUserLocation();
    }
    setPersonLayerData(personLayerData) {
        this.mapLayersManager.personLayer.setPersonLayerData(personLayerData);
        this.mapLayersManager.peopleOverlayLayer.hideMarkersByFeatureId(personLayerData === null || personLayerData === void 0 ? void 0 : personLayerData.spaceId);
    }
}
