import { indoorMapMarkerColor, indoorMapMarkerIcons, indoorMarkerBase, outdoorMarkerBase, spacePinAssets, spacePinIcons } from "../../Assets";
export class IconSvgCreator {
    constructor() {
        this.domParser = new DOMParser();
        this.createdSvgs = {};
    }
    createRouteMarkerSvg(iconType) {
        if (!this.createdSvgs[iconType]) {
            const svg = this.createIndoorMarkerSvg(indoorMapMarkerIcons[iconType], indoorMapMarkerColor[iconType]);
            this.createdSvgs[iconType] = svg;
        }
        return this.createdSvgs[iconType];
    }
    createSpacePinSvg(iconType, isFilledIcon) {
        if (this.createdSvgs[iconType]) {
            return Promise.resolve(this.createdSvgs[iconType]);
        }
        return this.getSpaceIconSvgFromLocal(iconType).then((icon) => {
            let svg = undefined;
            if (icon) {
                svg = this.createSvg(spacePinIcons.PinTop, icon);
            }
            else {
                svg = this.calculateMarkerOffset(this.getSvgElementFromString(spacePinIcons.PinTop));
            }
            this.createdSvgs[iconType] = svg;
            return this.createdSvgs[iconType];
        });
    }
    scaleMarker(iconType, iconSvg, zoomLevel) {
        const scaleFactor = 1 / 24;
        const baseMarkerSvg = this.getSvgElementFromString(this.createdSvgs[iconType].svg);
        const markerSvg = this.getSvgElementFromString(iconSvg);
        let width = baseMarkerSvg.getAttribute("width");
        let height = baseMarkerSvg.getAttribute("height");
        if (width && height) {
            width = parseInt(width);
            height = parseInt(height);
            markerSvg.setAttribute("width", `${Math.ceil(width * zoomLevel * scaleFactor)}`);
            markerSvg.setAttribute("height", `${Math.ceil(height * zoomLevel * scaleFactor)}`);
        }
        return this.calculateMarkerOffset(markerSvg);
    }
    createIndoorMarkerSvg(icon, color) {
        return this.createSvg(indoorMarkerBase, icon, { color, tagName: "path" }, 24);
    }
    createOutdoorMarkerSvg(icon, color) {
        return this.createSvg(outdoorMarkerBase, icon, { color, tagName: "path" }, 18);
    }
    createSvg(baseIcon, icon, config, preferredIconSize) {
        const baseIconSvg = this.getSvgElementFromString(baseIcon);
        const iconSvg = this.getSvgElementFromString(icon);
        if (config) {
            this.overrideSvgTagFillColor(baseIconSvg, config);
        }
        if (preferredIconSize) {
            this.setIconSvgSize(iconSvg, preferredIconSize);
        }
        this.appendIconToBaseIcon(baseIconSvg, iconSvg, preferredIconSize);
        return this.calculateMarkerOffset(baseIconSvg);
    }
    appendIconToBaseIcon(baseIconSvg, iconSvg, preferredIconSize) {
        const baseIconSvgWidth = baseIconSvg.viewBox.baseVal.width;
        const iconGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
        const iconSize = preferredIconSize ? preferredIconSize : iconSvg.viewBox.baseVal.width;
        const center = (baseIconSvgWidth - iconSize) / 2;
        iconGroup.setAttribute("transform", `translate(${center}, ${center})`);
        iconGroup.appendChild(iconSvg);
        baseIconSvg.appendChild(iconGroup);
    }
    setIconSvgSize(iconSvg, size) {
        iconSvg.setAttribute("width", `${size}`);
        iconSvg.setAttribute("height", `${size}`);
    }
    getSvgElementFromString(svgElement) {
        const document = this.domParser.parseFromString(svgElement, "application/xml");
        return document.getElementsByTagName("svg")[0];
    }
    overrideSvgTagFillColor(svgElement, config) {
        const svgTagNameElements = svgElement.getElementsByTagNameNS("http://www.w3.org/2000/svg", config.tagName);
        for (const tagNameElement of Array.from(svgTagNameElements)) {
            tagNameElement.setAttribute("fill", config.color);
        }
    }
    calculateMarkerOffset(markerSvg) {
        document.body.appendChild(markerSvg);
        const viewBox = markerSvg.viewBox.baseVal;
        const bbBox = markerSvg.getBBox();
        const ptPixelConversion = 1.33333;
        document.body.removeChild(markerSvg);
        return {
            svg: markerSvg.outerHTML,
            heightOffset: (viewBox.height - bbBox.height) / ptPixelConversion,
            widthOffset: (viewBox.height - bbBox.height) / ptPixelConversion
        };
    }
    getSpaceIconSvgFromLocal(spaceType) {
        return Promise.resolve(spacePinAssets[spaceType]).then((icon) => icon);
    }
}
