import { RouteDataProperty, getDestinationRoute, getFirstSegmentPosition, getInitialRoute, getLastSegmentPosition } from "../Layers";
import { Shape, data } from "azure-maps-control";
import { MapEventsEmitter } from "../Services/MapEventsEmitter";
import { createIdFloorDataMap } from "../Utilities/FeatureCollectionUtilities";
export class MapDataControl {
    constructor(dataSourcesManager) {
        this.dataSourcesManager = dataSourcesManager;
    }
    setSensorProperties(idSensorPropsMap) {
        const featuresDataSource = this.dataSourcesManager.getFeaturesDataSource();
        Object.keys(idSensorPropsMap).forEach((id) => {
            const shapeToUpdate = featuresDataSource.getShapeById(id);
            if (shapeToUpdate) {
                const sensorProperties = idSensorPropsMap[id];
                const currentProperties = shapeToUpdate.getProperties();
                Object.keys(sensorProperties).forEach((key) => {
                    if (currentProperties[key] !== sensorProperties[key]) {
                        shapeToUpdate.addProperty(key, sensorProperties[key]);
                    }
                });
            }
        });
    }
    setRouteData(routeData) {
        if (routeData) {
            try {
                this.setRoutePinsData(routeData);
                this.setRouteLineStringData(routeData);
                MapEventsEmitter.getInstance().emit("routeLayerRendered", routeData);
            }
            catch (error) {
                console.error(`AzureMaps-MapDataControl: Error set route data`, {
                    routeData,
                    error
                });
            }
        }
        else {
            this.dataSourcesManager.setRouteData([]);
            this.dataSourcesManager.setRoutePinsData([]);
            this.dataSourcesManager.setRouteClickablePinsData([]);
            MapEventsEmitter.getInstance().emit("routeLayerRendered", undefined);
        }
    }
    setRouteLineStringData(routeData) {
        this.dataSourcesManager.setRouteData(routeData.floorRoutes.map((route) => new Shape(new data.Feature(new data.LineString(route.segments.map((segment) => [segment.lng, segment.lat])), {
            [RouteDataProperty.FloorId]: route.floorId,
            [RouteDataProperty.FloorName]: route.floorName
        }))));
    }
    setRoutePinsData(routeData) {
        const routePinsDataShapes = [];
        const routeClickablePinsDataShapes = [];
        const floorDataMapById = createIdFloorDataMap(this.dataSourcesManager.getLevelsDataSource().toJson());
        const destinationRoute = getDestinationRoute(routeData);
        const destinationFloorOrdinal = floorDataMapById[destinationRoute.floorId].ordinal;
        routeData.floorRoutes.forEach((route) => {
            const isDestinationFloor = route.floorId === destinationRoute.floorId;
            if (route.segments.length > 1) {
                routePinsDataShapes.push(this.createRoutePinShape(getFirstSegmentPosition(route), route.floorId, route.floorName, true));
            }
            if (isDestinationFloor) {
                routePinsDataShapes.push(this.createRoutePinShape(getLastSegmentPosition(route), route.floorId, route.floorName, false));
            }
            else {
                routeClickablePinsDataShapes.push(this.createRouteClickablePinShape(getLastSegmentPosition(route), route.floorId, route.floorName, routeData.floorTraversalType, destinationRoute.floorId, destinationRoute.floorName, floorDataMapById[route.floorId].ordinal < destinationFloorOrdinal));
            }
            delete floorDataMapById[route.floorId];
        });
        Object.values(floorDataMapById).forEach((floorData) => {
            routeClickablePinsDataShapes.push(this.createRouteClickablePinShape(getFirstSegmentPosition(getInitialRoute(routeData)), floorData.floorId, floorData.floorName, routeData.floorTraversalType, destinationRoute.floorId, destinationRoute.floorName, floorData.ordinal < destinationFloorOrdinal));
        });
        this.dataSourcesManager.setRoutePinsData(routePinsDataShapes);
        this.dataSourcesManager.setRouteClickablePinsData(routeClickablePinsDataShapes);
    }
    createRoutePinShape(position, floorId, floorName, isStartPin) {
        return new Shape(new data.Feature(new data.Point(position), {
            [RouteDataProperty.FloorId]: floorId,
            [RouteDataProperty.FloorName]: floorName,
            [RouteDataProperty.IsStartPin]: isStartPin
        }));
    }
    createRouteClickablePinShape(position, floorId, floorName, floorTraversalType, destinationFloorId, destinationFloorName, isGoingUp) {
        return new Shape(new data.Feature(new data.Point(position), {
            [RouteDataProperty.FloorId]: floorId,
            [RouteDataProperty.FloorName]: floorName,
            [RouteDataProperty.FloorTraversalType]: floorTraversalType,
            [RouteDataProperty.DestinationFloorId]: destinationFloorId,
            [RouteDataProperty.DestinationFloorName]: destinationFloorName,
            [RouteDataProperty.IsGoingUp]: isGoingUp
        }));
    }
}
