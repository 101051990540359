export var DeviceType;
(function (DeviceType) {
    DeviceType["Mobile"] = "mobile";
    DeviceType["Web"] = "web";
    DeviceType["Kiosk"] = "kiosk";
})(DeviceType || (DeviceType = {}));
export var MapTheme;
(function (MapTheme) {
    MapTheme["Light"] = "light";
    MapTheme["Dark"] = "dark";
})(MapTheme || (MapTheme = {}));
