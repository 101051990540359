import { MapTheme } from "../Components/AzureMapComponent.Types";
export const mapStyleDark = {
    theme: "dark",
    indoor: "indoor_dark",
    outdoor: "grayscale_dark"
};
export const mapStyleLight = {
    theme: "light",
    indoor: "indoor_light",
    outdoor: "road"
};
export const mapThemes = {
    [MapTheme.Dark]: mapStyleDark,
    [MapTheme.Light]: mapStyleLight
};
