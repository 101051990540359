import { getPersonaInitialsColor } from "@fluentui/react";
export const getPersonLayerHtmlContent = (displayName, imageString) => {
    const divElement = document.createElement("div");
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("class", "person-layer-marker-shadow");
    svg.setAttribute("viewBox", "0 0 80 92");
    svg.setAttribute("width", "80");
    svg.setAttribute("height", "92");
    svg.setAttribute("fill", "none");
    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("fill", "#FFF");
    path.setAttribute("d", "M56.4776 71.3236C65.7781 65.7366 72 55.5628 72 43.939C72 26.2995 57.6729 12 40 12C22.3271 12 8 26.2995 8 43.939C8 55.5628 14.2219 65.7366 23.5224 71.3236H23.4823C23.6115 71.3909 23.7391 71.459 23.8656 71.5271C24.1589 71.6984 24.4552 71.8653 24.7547 72.0275C30.4693 75.3773 32.9635 79.5295 34.8427 82.6577C36.4641 85.3572 37.6271 87.2941 40.0068 87.2941C42.3859 87.2941 43.549 85.3572 45.1703 82.6577C47.0724 79.4908 49.6057 75.2741 55.4719 71.9032C55.6219 71.8206 55.7708 71.7363 55.9193 71.6514C56.1193 71.5411 56.3229 71.432 56.5307 71.3236H56.4776Z");
    const pinTop = imageString ? createImageElement(imageString) : createInitialsElement(displayName);
    svg.appendChild(path);
    divElement.appendChild(svg);
    divElement.appendChild(pinTop);
    return divElement;
};
const createImageElement = (imageString) => {
    const divElement = document.createElement("div");
    const image = document.createElement("img");
    image.src = imageString;
    image.setAttribute("class", "person-layer-marker");
    divElement.appendChild(image);
    return divElement;
};
const createInitialsElement = (displayName) => {
    const initials = document.createElement("div");
    initials.setAttribute("class", "person-layer-marker");
    initials.style.backgroundColor = getPersonaInitialsColor({ primaryText: displayName });
    initials.innerText = getDisplayNameInitials(displayName);
    return initials;
};
const getDisplayNameInitials = (displayName) => {
    const regexResult = displayName.match("^[^(]*");
    const filteredName = regexResult ? regexResult[0] : displayName;
    const nameParts = filteredName.trim().split(" ");
    const firstInitial = nameParts[0].charAt(0).toUpperCase();
    const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
    if (nameParts.length === 1) {
        return firstInitial;
    }
    return firstInitial + lastInitial;
};
