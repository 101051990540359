// TODO: Joey todo: is this service still needed? if not, will remove it.
var DtdlTilesetService = /** @class */ (function () {
    function DtdlTilesetService(httpService, url) {
        this.httpService = httpService;
        this.url = url;
    }
    DtdlTilesetService.prototype.getTileSetAndDatasetIds = function (spaceId) {
        return this.httpService
            .get(this.url + "api/v1.0/spaces/" + spaceId, {
            params: { includes: "map", type: "building" }
        })
            .then(function (res) {
            return {
                datasetId: res.data.mapData[0].datasetId,
                tilesetId: res.data.mapData[0].tilesetId
            };
        });
    };
    return DtdlTilesetService;
}());
export { DtdlTilesetService };
