export function findFloorIdByOrdinal(featureCollection, ordinal) {
    var _a, _b;
    if (!featureCollection.features) {
        return undefined;
    }
    if (featureCollection.features[0].properties.ordinal === undefined) {
        return undefined;
    }
    const levelFeature = featureCollection.features.find((feature) => { var _a; return ((_a = feature.properties) === null || _a === void 0 ? void 0 : _a.ordinal) === ordinal; });
    return {
        floorId: levelFeature === null || levelFeature === void 0 ? void 0 : levelFeature.id,
        floorName: (_a = levelFeature === null || levelFeature === void 0 ? void 0 : levelFeature.properties) === null || _a === void 0 ? void 0 : _a.name,
        ordinal: (_b = levelFeature === null || levelFeature === void 0 ? void 0 : levelFeature.properties) === null || _b === void 0 ? void 0 : _b.ordinal
    };
}
export function createOrdinalFloorDataMap(featureCollection) {
    var _a, _b, _c;
    const ordinalFloorDataMap = {};
    for (const feature of featureCollection.features) {
        if (((_a = feature.properties) === null || _a === void 0 ? void 0 : _a.ordinal) !== undefined) {
            ordinalFloorDataMap[feature.properties.ordinal] = {
                floorId: feature.id,
                floorName: (_b = feature.properties) === null || _b === void 0 ? void 0 : _b.name,
                ordinal: (_c = feature.properties) === null || _c === void 0 ? void 0 : _c.ordinal
            };
        }
    }
    return ordinalFloorDataMap;
}
export function createIdFloorDataMap(featureCollection) {
    var _a, _b;
    const idFloorDataMap = {};
    for (const feature of featureCollection.features) {
        if (feature.id) {
            idFloorDataMap[feature.id] = {
                floorId: feature.id,
                floorName: (_a = feature.properties) === null || _a === void 0 ? void 0 : _a.name,
                ordinal: (_b = feature.properties) === null || _b === void 0 ? void 0 : _b.ordinal
            };
        }
    }
    return idFloorDataMap;
}
