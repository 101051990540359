import { data } from "azure-maps-control";
export function getInitialRoute(routeData) {
    if (invalidRouteData(routeData))
        throw new Error("Unable to get initial route, routeData has no floor routes.");
    return routeData.floorRoutes[0];
}
export function getDestinationRoute(routeData) {
    if (invalidRouteData(routeData))
        throw new Error("Unable to get destination route, routeData has no floor routes.");
    return routeData.floorRoutes[routeData.floorRoutes.length - 1];
}
export function getFirstSegmentPosition(route) {
    if (invalidRoute(route))
        throw new Error("Unable to get first segment of the route, route has no segments.");
    const segment = route.segments[0];
    return new data.Position(segment.lng, segment.lat);
}
export function getLastSegmentPosition(route) {
    if (invalidRoute(route))
        throw new Error("Unable to get last segment of the route, route has no segments.");
    const segment = route.segments[route.segments.length - 1];
    return new data.Position(segment.lng, segment.lat);
}
export function invalidRouteData(routeData) {
    return routeData.floorRoutes.length === 0;
}
export function invalidRoute(route) {
    return route.segments.length === 0;
}
