export class IndoorMapControl {
    constructor(map, indoorManager, dataSourcesManager) {
        this.map = map;
        this.indoorManager = indoorManager;
        this.dataSourcesManager = dataSourcesManager;
    }
    goToFloor(floorId) {
        const facilityId = this.indoorManager.getCurrentFacilityDetails()[0];
        const floor = this.dataSourcesManager.getLevelsDataSource().getShapeById(floorId);
        if (floor) {
            const floorProps = floor.getProperties();
            this.indoorManager.setFacility(facilityId, floorProps.ordinal);
        }
    }
}
