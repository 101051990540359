import { mapThemes } from "../Types/MapStyles";
export class MapControl {
    constructor(map) {
        this.map = map;
    }
    zoomIn(zoomStep = 0.5) {
        var _a;
        const currentZoom = (_a = this.map.getCamera().zoom) === null || _a === void 0 ? void 0 : _a.valueOf();
        if (currentZoom) {
            this.map.setCamera({ zoom: currentZoom + zoomStep });
        }
    }
    zoomOut(zoomStep = 0.5) {
        var _a;
        const currentZoom = (_a = this.map.getCamera().zoom) === null || _a === void 0 ? void 0 : _a.valueOf();
        if (currentZoom) {
            this.map.setCamera({ zoom: currentZoom - zoomStep });
        }
    }
    setOutdoorMapCenter(lat, lng) {
        this.map.setCamera({ center: [lng, lat] });
    }
    setZoom(zoomLevel) {
        this.map.setCamera({ zoom: zoomLevel });
    }
    setTheme(theme) {
        const currentStyle = this.map.getStyle().style;
        if (currentStyle === null || currentStyle === void 0 ? void 0 : currentStyle.includes("indoor")) {
            this.map.setStyle({ style: mapThemes[theme].indoor });
        }
        else {
            this.map.setStyle({ style: mapThemes[theme].outdoor });
        }
    }
    setMapBearing(bearing) {
        this.map.setCamera({ bearing });
    }
}
