export var MapLayerIds;
(function (MapLayerIds) {
    MapLayerIds["BusynessLayer"] = "busynessLayer";
    MapLayerIds["SpacePinsLayer"] = "spacePinsLayer";
    MapLayerIds["PoiLayer"] = "poiLayer";
    MapLayerIds["PeopleOverlayLayer"] = "peopleOverlayLayer";
    MapLayerIds["RouteLayer"] = "routeLayer";
})(MapLayerIds || (MapLayerIds = {}));
export var DataSourceIds;
(function (DataSourceIds) {
    DataSourceIds["Levels"] = "levels";
    DataSourceIds["Features"] = "features";
    DataSourceIds["PointsOfInterest"] = "pointsOfInterest";
    DataSourceIds["Route"] = "route";
    DataSourceIds["RoutePins"] = "routePins";
    DataSourceIds["RouteClickablePins"] = "routeClickablePins";
    DataSourceIds["BuildingOverlay"] = "buildingOverlay";
})(DataSourceIds || (DataSourceIds = {}));
export var LayerIds;
(function (LayerIds) {
    LayerIds["BusynessIconLayer"] = "busynessIconLayer";
    LayerIds["BusynessLabelLayer"] = "busynessLabelLayer";
    LayerIds["SensorPolygonLayer"] = "sensorPolygonLayer";
    LayerIds["SensorBaseLayer"] = "sensorBaseLayer";
    LayerIds["SensorOutlineLayer"] = "sensorOutlineLayer";
    LayerIds["SpacePinsTopLayer"] = "spacePinsTopLayer";
    LayerIds["SpacePinsTailLayer"] = "spacePinsTailLayer";
    LayerIds["PoiIconLayer"] = "poiIconLayer";
    LayerIds["PoiGrayFloorOverlayLayer"] = "grayFloorOverlayLayer";
    LayerIds["RouteSegmentsLayer"] = "routeSegmentsLayer";
    LayerIds["RoutePinsLayer"] = "routePinsLayer";
    LayerIds["RouteClickablePinsLayer"] = "routeClickablePinsLayer";
    LayerIds["BuildingOverlayMarkerLayer"] = "buildingOverlayMarkerLayer";
    LayerIds["BuildingOverlayClusterMarkerLayer"] = "buildingOverlayClusterMarkerLayer";
    LayerIds["BuildingOverlayClusterLabelLayer"] = "buildingOverlayClusterLabelLayer";
})(LayerIds || (LayerIds = {}));
export var MapFeatureProperty;
(function (MapFeatureProperty) {
    MapFeatureProperty["Category"] = "category";
    MapFeatureProperty["FloorId"] = "floorId";
    MapFeatureProperty["OriginalId"] = "originalId";
    MapFeatureProperty["Name"] = "name";
    MapFeatureProperty["PoiType"] = "poiType";
})(MapFeatureProperty || (MapFeatureProperty = {}));
export var PoiType;
(function (PoiType) {
    PoiType["AEDDevice"] = "AEDDevice";
    PoiType["KioskLocation"] = "KioskLocation";
    PoiType["FireExtinguisher"] = "FireExtinguisher";
    PoiType["EmergencyExit"] = "EmergencyExit";
    PoiType["BombShelter"] = "BombShelter";
})(PoiType || (PoiType = {}));
