import { data } from "azure-maps-control";
import { MapFeatureProperty } from "../../Constants/Identifiers";
export function findSpaceLocation(spaceId, dataSource) {
    const featureShape = dataSource.getShapeById(spaceId);
    if (featureShape) {
        return {
            coordinate: data.BoundingBox.getCenter(featureShape.getBounds()),
            floorId: featureShape.getProperties()[MapFeatureProperty.FloorId]
        };
    }
    return undefined;
}
