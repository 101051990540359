import { RouteDataProperty, TraversalType } from "./RouteLayer.Types";
import { RouteIconLabels } from "../../Assets";
export const routeSegmentColor = "#0078D4";
export const routeSegmentWidth = 5;
export const panCameraZoomStep = 1.5;
export const panCameraBoundsPadding = { top: 85, bottom: 85, right: 75, left: 75 };
export const pinIconSize = 1;
export const pinOffset = [-0.5, 24];
export const routePinsIconOptions = {
    image: [
        "case",
        ["==", ["get", RouteDataProperty.IsStartPin], true],
        RouteIconLabels.Start,
        ["==", ["get", RouteDataProperty.IsStartPin], false],
        RouteIconLabels.End,
        "marker-blue"
    ],
    allowOverlap: true,
    ignorePlacement: true,
    offset: pinOffset,
    size: pinIconSize
};
export const routeClickablePinsIconOptions = {
    image: [
        "case",
        [
            "all",
            ["==", ["get", RouteDataProperty.FloorTraversalType], TraversalType.Stairwell],
            ["==", ["get", RouteDataProperty.IsGoingUp], true]
        ],
        RouteIconLabels.StairUp,
        [
            "all",
            ["==", ["get", RouteDataProperty.FloorTraversalType], TraversalType.Stairwell],
            ["==", ["get", RouteDataProperty.IsGoingUp], false]
        ],
        RouteIconLabels.StairDown,
        [
            "all",
            ["==", ["get", RouteDataProperty.FloorTraversalType], TraversalType.Elevator],
            ["==", ["get", RouteDataProperty.IsGoingUp], true]
        ],
        RouteIconLabels.ElevatorUp,
        [
            "all",
            ["==", ["get", RouteDataProperty.FloorTraversalType], TraversalType.Elevator],
            ["==", ["get", RouteDataProperty.IsGoingUp], false]
        ],
        RouteIconLabels.ElevatorDown,
        "marker-blue"
    ],
    allowOverlap: true,
    ignorePlacement: true,
    offset: pinOffset,
    size: pinIconSize
};
export const routeClickablePinsTextOptions = {
    textField: ["concat", "To Floor ", ["get", RouteDataProperty.DestinationFloorName]],
    offset: [0, -4.5],
    font: ["SegoeUi-SemiBold"],
    color: "white",
    haloColor: "#010202",
    haloBlur: 0.5,
    haloWidth: 1,
    allowOverlap: true
};
