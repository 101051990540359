import { data } from "azure-maps-control";
export function convertDipToFeatureCollection(dipData) {
    const features = [];
    dipData.items.forEach((item, index) => {
        var _a, _b;
        if (item.hasLocation.relationships.length === 0) {
            return;
        }
        const position = new data.Point([
            item.hasLocation.relationships[0].target.longitude,
            item.hasLocation.relationships[0].target.latitude
        ]);
        features.push(new data.Feature(position, {
            buildingId: item.$dtId,
            buildingName: item.name,
            hasCafe: (_b = (_a = item.amenities) === null || _a === void 0 ? void 0 : _a.HasCafe) !== null && _b !== void 0 ? _b : false
        }, index));
    });
    return new data.FeatureCollection(features);
}
